import { IClientLevelStateFacility, noClientLevelStateFacility } from './IClientLevelStore';

/*
 * Enumerated client level store action types
 */
export enum ClientLevelActionNames {
  RESET = 'RESET',
  SET_FACILITY_LIST = 'SET_FACILITY_LIST',
  SET_FAILED_TO_LOAD_FACILITY_LIST = 'SET_FAILED_TO_LOAD_FACILITY_LIST',
  SET_FACILITY_LIST_POPULATED = 'SET_FACILITY_LIST_POPULATED',
  SET_FACILITY_LIST_LOADING = 'SET_FACILITY_LIST_LOADING',
  UPDATE_FACILITY_ATTRIBUTE = 'UPDATE_FACILITY_ATTRIBUTE',
}

/**
 * Type of payload for UPDATE_FACILITY_ATTRIBUTE client level action
 */
interface UpdateFacilityAttributePayload<T extends keyof IClientLevelStateFacility> {
  facilityId?: string;
  id: string; // duplicate of facilityId ??? used in store
  attribute?: keyof IClientLevelStateFacility;
  key: T; // duplicate with attribute ??? used in store and reducer
  value: typeof noClientLevelStateFacility[T];
}

/**
 * Types of client level actions
 */
export type ClientLevelAction =
  | { type: ClientLevelActionNames.RESET; payload: null }
  | { type: ClientLevelActionNames.SET_FACILITY_LIST; payload: IClientLevelStateFacility[] }
  | { type: ClientLevelActionNames.SET_FAILED_TO_LOAD_FACILITY_LIST; payload: boolean }
  | { type: ClientLevelActionNames.SET_FACILITY_LIST_POPULATED; payload: boolean }
  | { type: ClientLevelActionNames.SET_FACILITY_LIST_LOADING; payload: boolean }
  | {
      type: ClientLevelActionNames.UPDATE_FACILITY_ATTRIBUTE;
      payload: UpdateFacilityAttributePayload<keyof IClientLevelStateFacility>;
    };
