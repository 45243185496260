import { GridValueGetter } from '@mui/x-data-grid';
import { ILocationDataST } from 'codegen/warehouse_status';
import { formatShortDate } from '../formatShortDate';

export const wmsDateGroupingValueGetter: GridValueGetter<ILocationDataST, string> = (
  value,
  row,
) => {
  const dateTimeString = typeof row.wms_status !== 'string' && row.wms_status?.changed_at;
  if (!dateTimeString) {
    return 'N/A';
  }

  const dateTime = new Date(dateTimeString);
  return formatShortDate(dateTime);
};
