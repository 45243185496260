import { GridValueGetter } from '@mui/x-data-grid';
import { ILocationDataST, IWMSSlotStatusST } from 'codegen/warehouse_status';

export const exclusionStatusValueGetter: GridValueGetter<ILocationDataST, boolean> = (
  value,
  row,
): boolean => {
  const wmsStatus = row.wms_status as IWMSSlotStatusST | undefined;

  const exclusionStatusWMS = wmsStatus?.exclusion_status;
  const exclusionStatusVerity = row.slot_settings?.exclusion_status;

  if (exclusionStatusVerity === 'EXCLUDE' || exclusionStatusWMS === 'EXCLUDE') {
    return true;
  }

  return false;
};
