import { useState } from 'react';

import { Button, Typography, useTheme } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { useLocation } from 'react-router-dom';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { DroneIcon } from 'components/common/FacilityMenu/Icons/DroneIcon';

import { Box } from 'components/common/Box';
import { ModalBase } from 'components/ModalsAndPopups/ModalBase';
import { Spinner } from 'components/common/Spinner';

import { groundControlServices } from '../../services/GroundControlServices';

import { GroundControlModalsActionNames } from '../../store/Modals/groundControlModals/groundControlModalsActions';

import { useRequestController } from '../../hooks';
import { useGroundControlStore } from '../../store/GroundControl/groundControlLevelStore';
import { GroundControlActionNames } from '../../store/GroundControl/groundControlLevelActions';
import { useGroundControlModalsStore } from '../../store/Modals/groundControlModals/groundControlModalsStore';

/**
 * Interface for Lock modal props
 */
interface ILockModalProps {
  opened: boolean;
  nDronesFlying: number;
  facilityName: string;
  flightDomainId: string;
  flightDomainName: string;
  noData: boolean;
}

export const LockModal = (props: ILockModalProps) => {
  const { opened, nDronesFlying, flightDomainId, flightDomainName, noData, facilityName } = props;

  const [loading, setLoading] = useState(false);
  const { stateGroundControl, dispatchGroundControlLevel } = useGroundControlStore();
  const { dispatchGroundControlModals } = useGroundControlModalsStore();

  const theme = useTheme() as any;

  const systemId = useLocation().pathname.split('/')[1];

  const { requestController } = useRequestController('Lock System Modal');

  /**
   * Handle flight domain lock
   */
  const handleSystemLock = () => {
    const lp = getLogPrefixForType('FUNCTION', 'handleSystemLock');
    const reservation = requestController.reserveSlotForRequest();

    return requestController.doRequest({
      request: groundControlServices.lockFlightDomain,
      requestParams: [systemId, flightDomainId, reservation.signal],
      callbackBeforeSend: () => {
        console.debug(lp, 'Locking the flight domain...');
        setLoading(true);
      },
      messageErrorFallback: 'Error occured while locking the flight domain.',
      messageSuccess: 'Flight domain locked',
      callbackSuccess: async () => {
        console.debug(lp, 'Flight domain locked');
        dispatchGroundControlModals({
          type: GroundControlModalsActionNames.LOCK_MODAL,
        });
        dispatchGroundControlLevel({
          type: GroundControlActionNames.SET_ALERT_INFO,
          payload: {
            key: 'statusCard',
            variant: stateGroundControl.activeAlertVariant,
            message: 'System has been locked.',
          },
        });
      },
      callbackFinally: () => setLoading(false),
    });
  };

  const closeModal = () =>
    dispatchGroundControlModals({
      type: GroundControlModalsActionNames.LOCK_MODAL,
    });

  return (
    <ModalBase
      testId="c-lock-modal"
      closeButton={true}
      disableBackdropClick={true}
      opened={opened}
      handleClose={closeModal}
      title={
        <Box display="flex" p={2} alignItems="flex-start">
          <Box mt={0.6} mr={1}>
            <LockIcon
              sx={{ fontSize: '2rem', fontWeight: 'bold', color: theme.palette.grey[700] }}
            />
          </Box>
          <Box data-testid="c-lock-modal-title" textAlign="left" mb={1}>
            <Typography style={{ fontWeight: 'bold' }} color="textPrimary.main" variant="h5">
              Lock System
            </Typography>
            <Typography
              textTransform="uppercase"
              data-testid="c-lock-modal-subtitle"
              color="secondary"
              variant="subtitle1"
            >
              {`${facilityName} - ${flightDomainName}`}
            </Typography>
          </Box>
        </Box>
      }
      actionButtons={
        <>
          <Button
            data-testid="c-lock-modal-btn-cancel"
            onClick={closeModal}
            variant="outlined"
            fullWidth
            color="primary"
          >
            Cancel
          </Button>
          <Button
            data-testid="c-lock-modal-btn-confirm"
            onClick={handleSystemLock}
            variant="contained"
            fullWidth
            color="primary"
            startIcon={<LockIcon />}
          >
            Lock system
          </Button>
        </>
      }
    >
      <Box data-testid="c-lock-modal-content">
        {!noData ? (
          <Typography>Locking the system will initiate the following processes:</Typography>
        ) : (
          <Typography>
            The connections is lost, but you can try to lock the system. In case the system gets
            locked the following process will be initiated:
          </Typography>
        )}

        <Box my={3} display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <DroneIcon fontSize="40px" color={theme.palette.grey[600]} />
            <Typography ml={5}>Flying drones return to chargers</Typography>
          </Box>
          <Typography variant="h4" color="textPrimary.main">
            {noData ? '-' : nDronesFlying}
          </Typography>
        </Box>
        <Typography>
          You will not be able to cancel this operation. Would you like to proceed and lock the
          system?
        </Typography>
      </Box>

      {loading && <Spinner />}
    </ModalBase>
  );
};
