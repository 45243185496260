import { GridValueGetter } from '@mui/x-data-grid';
import { ILocationDataST } from 'codegen/warehouse_status';
import { replaceArrayItems } from 'common/functions/arrayFunctions';
import { isArray, isEmpty } from 'lodash';
import { emptyCellString } from './collectionDelayValueGetter';

export const wmsQuantityValueGetter: GridValueGetter<ILocationDataST> = (value, row) => {
  const values =
    typeof row.wms_status !== 'string' &&
    row.wms_status?.state !== 'EMPTY' &&
    isArray(row.wms_status?.qtys) &&
    !isEmpty(row.wms_status?.qtys)
      ? replaceArrayItems(row.wms_status?.qtys, null, emptyCellString)
      : [emptyCellString];

  return values?.join(', ');
};
