import React from 'react';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import { Box } from 'components/common/Box';
import { baseCardStyle } from './styles';
import '../facilityCardStyle.css';
import { SettingsCardContent } from './SettingsCardContent';
import { ActionButton, SettingsItem } from './models/settingsCard.model';

const useStyles = makeStyles()((theme: Theme) => ({
  ...baseCardStyle(theme),
}));

interface ISettingsCardProps {
  /**
   * if this value is true, spinner will be shown in content section, default=false
   */
  isLoading?: boolean;
  title: string;
  subtitle?: string | React.ReactElement;
  items?: SettingsItem[];
  content?: any;
  actionButtons?: any;
  testId: string;
  info?: any;
}

export const SettingsCard = (props: ISettingsCardProps) => {
  const {
    isLoading = false,
    title,
    subtitle,
    items = [],
    content,

    actionButtons,
    testId,
    info = null,
  } = props;

  const { classes } = useStyles();

  return (
    <Card data-testid={testId} className={clsx(classes.card, 'settings-card')} elevation={3}>
      <CardHeader
        className={classes.cardSection}
        title={title}
        subheader={subtitle}
        action={
          <Box
            className={classes.actionContainer}
            display="flex"
            alignItems="center"
            flexDirection="column"
          />
        }
        classes={{
          title: classes.title,
          subheader: classes.subheader,
        }}
      />
      <Divider />
      <SettingsCardContent items={items} isLoading={isLoading} content={content} info={info} />
      {actionButtons && (
        <CardActions className={classes.cardSection}>
          {!isLoading &&
            actionButtons.map((button: ActionButton) => (
              <Button
                onClick={button.clickHandler}
                variant={button.variant}
                disabled={button.disabled}
                size={button.size}
                fullWidth={button.fullWidth}
                color={button.color}
                key={`${title}-${testId}-${button.label}`}
              >
                {button.label}
              </Button>
            ))}
        </CardActions>
      )}
    </Card>
  );
};
