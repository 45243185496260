import { isValidEmailFormat } from 'utils/isValidEmailFormat';

/**
 * Verify whether the given email address is available
 * @param email email address
 * @param usersEmails list of email addresses already in use
 * @param editMode flag indicating whether the check is for an existing or new email
 * @returns nothing if successful, an error string otherwise
 */
export const isEmailAvailable = (email: string, usersEmails: any, editMode: boolean) => {
  if (!email) {
    return 'This field is required.';
  }
  if (email && !isValidEmailFormat(email)) {
    return 'Invalid email address';
  }

  const user = usersEmails.find((user: any) => user.email === email);
  if (!editMode && email && user?.username) {
    return `This email (${email}) is used by ${user?.username}`;
  }
  return '';
};
