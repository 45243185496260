import { useTheme } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';

import { getFlightDomainStatusColor } from 'common/functions/otherFunctions';
import { Box } from 'components/common/Box';
import { HEADER_HEIGHT } from 'components/Header/defaults/Header.defaults';
import { FleetStatus } from './fleet-status/FleetStatus';

import { IFlightDomainData } from '../../../store/GroundControl/IGroundControlStore';
import { LockUnlockSwitch } from './LockUnlockSwitch';
import { AccessAlertIcon } from './AccessAlertIcon';
import { VerticalDivider } from './StatusBarVerticalDivider';
import { getStatusBarText } from './getStatusBarText';

interface StatusBarProps {
  flightDomain: IFlightDomainData;
  isExecutingReturnHome: boolean;
  webSocketError: boolean;
  isExecutingEmergencyLanding: boolean;
  isInCoexistenceMode: boolean;
  dronesFlying: boolean;
  onLockSystem: () => void;
  onUnlockSystem: () => void;
  stickyStatusBar: boolean;
}

export const StatusBar = (props: StatusBarProps) => {
  const {
    flightDomain,
    isExecutingReturnHome,
    isExecutingEmergencyLanding,
    isInCoexistenceMode,
    dronesFlying,
    webSocketError,
    onLockSystem,
    onUnlockSystem,
    stickyStatusBar,
  } = props;

  const theme = useTheme();

  const { flight_domain_status, fleet_status_summary } = flightDomain;

  const statusColor = getFlightDomainStatusColor({
    locked: flight_domain_status?.locked || false,
    isExecutingReturnHome,
    isExecutingEmergencyLanding,
  });

  const statusText = getStatusBarText({
    fleet_status_summary,
    flight_domain_status,
    isExecutingEmergencyLanding,
    isInCoexistenceMode,
    dronesFlying,
  });

  return (
    <Box
      sx={{
        zIndex: 1200,
        top: stickyStatusBar ? 0 : HEADER_HEIGHT,
        right: 0,
        overflowY: 'hidden',
        position: 'sticky',
        transition: theme.transitions.create('top', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}
      width="100%"
    >
      <Accordion disableGutters square>
        <AccordionSummary
          data-testid="c-fd-status-bar"
          expandIcon={false}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{
            cursor: 'auto',
            background: statusColor,
            '& .MuiAccordionSummary-content': {
              margin: '0px 0px',
            },
          }}
        >
          <Grid justifyContent="space-between" sx={{ cursor: 'auto' }} width="100%" container>
            <Box display="flex" alignItems="center" sx={{ position: 'relative' }}>
              <Box display="flex" alignItems="center" sx={{ position: 'relative' }}>
                <AccessAlertIcon flight_domain_status={flight_domain_status} />
                <LockUnlockSwitch
                  flight_domain_status={flight_domain_status}
                  onLockSystem={onLockSystem}
                  onUnlockSystem={onUnlockSystem}
                  statusColor={statusColor}
                />
                <Typography
                  data-testid="c-fd-status-bar-fd-name"
                  sx={{ fontWeight: 500 }}
                  mx={2}
                  color="white"
                >
                  {flightDomain.flight_domain_name.toUpperCase()}
                </Typography>
                <VerticalDivider />
              </Box>
              <Typography data-testid="c-fd-status-bar-safe_status" mx={2} color="white">
                {statusText}
              </Typography>
            </Box>

            <Box width="300px" p={2}>
              <FleetStatus
                fleet_status_summary={fleet_status_summary}
                webSocketError={webSocketError}
              />
            </Box>
          </Grid>
        </AccordionSummary>
      </Accordion>
    </Box>
  );
};
