import { GridValueGetter } from '@mui/x-data-grid';
import { ILocationDataST } from 'codegen/warehouse_status';
import { getMatchingBarcodes } from '../barcodeFunctions';

export const matchingBarcodesValueGetter: GridValueGetter<ILocationDataST> = (
  value,
  row,
): number => {
  const matching = getMatchingBarcodes(row);
  return matching ? matching.length : 0;
};
