import { GridValueGetter } from '@mui/x-data-grid';
import { ILocationDataST, IVeritySlotStatusST } from 'codegen/warehouse_status';

export const wasFoundAtValueGetter: GridValueGetter<ILocationDataST> = (v, row): string => {
  const issue = row.issues && row.issues[0];
  const slotStatus: IVeritySlotStatusST | null =
    issue && issue.verity_slot_status_with_wms_bc !== 'string'
      ? (issue.verity_slot_status_with_wms_bc as IVeritySlotStatusST)
      : null;

  return slotStatus && slotStatus.slot_label !== issue.location ? slotStatus.slot_label : '';
};
