// axios
import axios from 'axios';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

import { singleRequestHandler } from 'common/requestHelpers';

import {
  getFacilityApiService,
  getReportSpecificationApiService,
  getWMSDataService,
} from './services';

const getFormDataForS3Upload = (presignedPostData: any, file: any) => {
  const formData = new FormData();

  Object.keys(presignedPostData.fields).forEach((key) => {
    formData.append(key, presignedPostData.fields[key]);
  });

  formData.append('file', file);

  return formData;
};

const uploadWMSFile = (
  presignedPostService: (...params: any) => any,
  file: File,
  setProgress: (percentage: number) => void,
  dispatcher?: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
) => {
  const uploadProgress = {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted);
    },
  };

  return singleRequestHandler({
    request: presignedPostService,
    dispatcher,
    messageSuccess: 'Presigned post URL is get successfully.',
    messageErrorFallback: 'Error getting presigned post URL.',
  }).then((response: any) => {
    const presignedPostData = response.data.presigned_post;
    const formData = getFormDataForS3Upload(presignedPostData, file);

    // Send request
    return singleRequestHandler({
      // we use axios directly here, because this url is loaded from the server
      // and it contains it's own keys
      request: axios.post,
      requestParams: [presignedPostData.url, formData, { ...uploadProgress }],
      dispatcher,
      messageSuccess: 'WMS data uploaded successfully.',
      messageErrorFallback: 'Error uploading WMS data.',
    });
  });
};

interface IWMSDataParams {
  from?: string;
  until?: string;
  limit?: number;
  pageToken?: string;
  latest?: 'true' | 'false';
}

/**
 * Get uploaded snapshot file/s.
 * @param {string} systemId System ID (of the Facility)
 * @param {object} params <IWMSDataParams>
 * @returns {object} <IWMSDataFilesGetResponseST>
 */
const getUploadedWMSData = (systemId: string, params: IWMSDataParams, signal: AbortSignal) =>
  getWMSDataService().getUploadedWMSData(
    systemId,
    params.from,
    params.until,
    params?.limit,
    params?.pageToken,
    params?.latest,
    { signal },
  );

/**
 * Get uploaded stock check file/s.
 * @param {string} systemId System ID (of the Facility)
 * @param {object} params <IWMSDataParams>
 * @returns {object} <IWMSStockCheckDataUploadGetResponseST>
 */
const getUploadedStockCheckData = (systemId: string, params: IWMSDataParams, signal: AbortSignal) =>
  getWMSDataService().getUploadedStockCheckData(
    systemId,
    params.from,
    params.until,
    params?.limit,
    params?.pageToken,
    params?.latest,
    { signal },
  );

/**
 * Get uploaded moved locations file/s.
 * @param {string} systemId System ID (of the Facility)
 * @param {object} params <IWMSDataParams>
 * @returns {object} <IWMSMovedLocationsDataUploadGetResponseST>
 */
const getUploadedMovedLocationsData = (
  systemId: string,
  params: IWMSDataParams,
  signal: AbortSignal,
) =>
  getWMSDataService().getUploadedMovedLocationsData(
    systemId,
    params.from,
    params.until,
    params?.limit,
    params?.pageToken,
    params?.latest,
    { signal },
  );

/**
 * Get Facility presigned post URL.
 * @param {string} systemId System ID (of the Facility)
 * @returns {object} <IFacilityPostResponseST>
 */
const getMapUploadPresignedUrl = (systemId: string, signal: AbortSignal) =>
  getFacilityApiService().postFacilityPresignedUrl(systemId, { signal });

/**
 * Get presigned post for WMS snapshot file upload.
 * @param {string} systemId System ID (of the Facility)
 * @param {object} data <IWMSDataUploadPostRequestST>
 * @returns {object} <IWMSDataUploadPostResponseST>
 */
const getWMSDataPresignedUrl = (systemId: string, data: any) =>
  getWMSDataService().getWMSDataPresignedUrl(systemId, data);

/**
 * Get presigned post for WMS stock check file upload.
 * @param {string} systemId System ID (of the Facility)
 * @param {object} data <IWMSStockCheckDataUploadPostRequestST>
 * @returns {object} <IWMSStockCheckDataUploadPostResponseST>
 */
const getStockCheckDataPresignedUrl = (systemId: string, data: any) =>
  getWMSDataService().getStockCheckDataPresignedUrl(systemId, data);

/**
 * Get presigned post for WMS moved locations file upload.
 * @param {string} systemId System ID (of the Facility)
 * @param {object} data <IWMSMovedLocationsDataUploadPostRequestST>
 * @returns {object} <IWMSMovedLocationsDataUploadPostResponseST>
 */
const getMovedLocationsDataPresignedUrl = (systemId: string, data: any) =>
  getWMSDataService().getMovedLocationsDataPresignedUrl(systemId, data);

/**
 * Create report specification.
 * @param {string} systemId System ID (of the Facility)
 * @param {object} file <IReportSpecificationPostRequestST>
 * @returns {object} <IReportSpecificationPostResponseST>
 */
const uploadReportSpecification = (systemId: string, file: any) =>
  getReportSpecificationApiService().uploadReportSpecification(systemId, file);

/**
 * Get all report specifications.
 * @param {string} systemId System ID (of the Facility)
 * @returns {object} <IReportSpecificationGetAllResponseST>
 */
const getReportSpecifications = (systemId: string) =>
  getReportSpecificationApiService().getReportSpecifications(systemId);

/**
 * Remove specific report specification.
 * @param {string} systemId System ID (of the Facility)
 * @param {string} reportSpecId Report Specification ID
 * @returns {object} <IReportSpecificationDeleteResponseST>
 */
const deleteReportSpecification = (systemId: string, reportSpecId: string) =>
  getReportSpecificationApiService().deleteReportSpecification(systemId, reportSpecId);

export const uploadServices = {
  uploadWMSFile,
  getUploadedWMSData,
  getUploadedStockCheckData,
  getUploadedMovedLocationsData,
  getMapUploadPresignedUrl,
  getWMSDataPresignedUrl,
  getStockCheckDataPresignedUrl,
  getMovedLocationsDataPresignedUrl,
  uploadReportSpecification,
  getReportSpecifications,
  deleteReportSpecification,
};
