import { createContext, useContext, useReducer } from 'react';
import { initialGroundControlModalsState } from './groundControlModalInitialState';
import { modalsReducer } from './groundControlModalsReducer';
import { IModalsStoreContext } from './IGroundControlModalsStore';

const initialContext: IModalsStoreContext = {
  stateGroundControlModals: initialGroundControlModalsState,
  dispatchGroundControlModals: () => {},
};

const Store = createContext(initialContext);

export const GroundControlModalsStoreProvider = ({ children }: any) => {
  const [stateGroundControlModals, dispatchGroundControlModals] = useReducer(
    modalsReducer,
    initialGroundControlModalsState,
  );

  return (
    <Store.Provider value={{ stateGroundControlModals, dispatchGroundControlModals }}>
      {children}
    </Store.Provider>
  );
};

export const useGroundControlModalsStore = () => useContext(Store);
