import { GridValueGetter } from '@mui/x-data-grid';
import { ILocationDataST } from 'codegen/warehouse_status';
import { formatShortDate } from '../formatShortDate';

export const firstFoundOnGroupingValueGetter: GridValueGetter<ILocationDataST, string> = (
  value,
  row,
): string => {
  const dateTimeString = row.issues[0]?.first_found_on;
  if (!dateTimeString) {
    return 'N/A';
  }

  const dateTime = new Date(dateTimeString);
  return formatShortDate(dateTime);
};
