import { Merged } from '@react-three/drei';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Color, Euler, Group, Vector3 } from 'three';
import { ThreeEvent } from '@react-three/fiber';
import { Bin3DContainerProps, Bin3DProps, colorMap, ModelsMap } from './model/bin3DProps.model';
import { useSvgMesh } from './hooks/useSvgMesh';
import { useBin3DMesh } from './hooks/useBin3DMesh';

const DEFAULT_ICON_SCALE = 0.02;
const DEFAULT_ICON_DIMENSION = 24;
const NORMALISED_HALF = (DEFAULT_ICON_DIMENSION * DEFAULT_ICON_SCALE) / 2;
const TRANSLATE_BY_HALF = new Vector3(-NORMALISED_HALF, -NORMALISED_HALF, 0);

const IconComponentFallbackWhichRendersIfBinDoesNotHaveAnIcon = () => null;

const Bin3D = ({
  models,
  status,
  dimensions,
  position,
  current,
  name,
  onClick,
  normal = new Vector3(1, 0, 0),
}: Bin3DProps) => {
  const [isHover, setHover] = useState(false);

  const onOver = useCallback((event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation();
    setHover(true);
  }, []);

  const onLeave = useCallback((event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation();
    setHover(false);
  }, []);

  const handleClick = useCallback(
    (event: ThreeEvent<MouseEvent>) => {
      event.stopPropagation();
      onClick?.(event);
    },
    [onClick],
  );

  const displayProps = useMemo(() => colorMap[status] ?? colorMap.NOT_SCANNED, [status]);
  const color = useMemo(
    () => new Color(isHover || current ? displayProps.hover : displayProps.color),
    [isHover, displayProps.color, displayProps.hover, current],
  );

  const iconPosition = useMemo(() => {
    const iconOffset = 0.01;
    const offset = new Vector3(
      dimensions.x / 2 + iconOffset,
      dimensions.y / 2 + iconOffset,
      0,
    ).multiply(normal);
    return offset;
  }, [dimensions, normal]);

  const iconRotation = useMemo(
    () => new Euler(-Math.PI / 2, (Math.PI / 2) * normal.x, 0),
    [normal.x],
  );

  const Icon = useMemo(
    () => models[status] ?? IconComponentFallbackWhichRendersIfBinDoesNotHaveAnIcon,
    [models, status],
  );

  return (
    <group position={position} onClick={handleClick} name={name}>
      <models.Bin3DMesh
        scale={dimensions}
        color={current ? color.offsetHSL(0, 0.4, -0.1) : color}
        onPointerOver={onOver}
        onPointerLeave={onLeave}
      />

      {models[status] ? (
        <group position={iconPosition} rotation={iconRotation}>
          <Icon scale={DEFAULT_ICON_SCALE} position={TRANSLATE_BY_HALF} />
        </group>
      ) : null}
    </group>
  );
};

export const Bin3DContainer = forwardRef(
  ({ limit = 100_000, bins }: Bin3DContainerProps, ref: React.Ref<Group>) => {
    const ISSUE = useSvgMesh(colorMap.ISSUE.iconUrl);
    const POTENTIAL_ISSUE = useSvgMesh(colorMap.POTENTIAL_ISSUE.iconUrl);
    const EXCLUDED = useSvgMesh(colorMap.EXCLUDED.iconUrl);
    const NOT_SCANNED = useSvgMesh(colorMap.NOT_SCANNED.iconUrl);

    const Bin3DMesh = useBin3DMesh();
    const meshes = useMemo(
      () => ({
        ISSUE,
        POTENTIAL_ISSUE,
        EXCLUDED,
        NOT_SCANNED,
        Bin3DMesh,
      }),
      [ISSUE, POTENTIAL_ISSUE, EXCLUDED, NOT_SCANNED, Bin3DMesh],
    );
    useEffect(() => {
      const cleanupThreeObjects = () =>
        [ISSUE, POTENTIAL_ISSUE, EXCLUDED, NOT_SCANNED, Bin3DMesh].forEach((mesh) => {
          mesh.geometry.dispose();
          mesh.material.dispose();
        });
      return cleanupThreeObjects;
    }, [Bin3DMesh, EXCLUDED, ISSUE, NOT_SCANNED, POTENTIAL_ISSUE]);
    return (
      <Merged ref={ref} limit={limit} meshes={meshes}>
        {(loadedModels: ModelsMap) => (
          <>
            {bins.map((bin) => (
              <Bin3D key={bin.id ?? '' + bin.name + bin.status} models={loadedModels} {...bin} />
            ))}
          </>
        )}
      </Merged>
    );
  },
);

Bin3DContainer.displayName = 'Bin3DContainer';
