import { barcodeStatusEnum } from 'components/ModalsAndPopups/LocationModal/barcodes/models/BarcodeRow.model';
import { Theme } from '@mui/material';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { BarcodeMatchLogic } from './getBarcodeMuiColor.util';

export const getMissingColor = (
  barcodeMatchLogic: BarcodeMatchLogic,
  issueLogic: IRuleActionSTIssueLogicEnum | null = null,
  theme: Theme,
) => {
  const areMissingBarcodePotential = issueLogic === 'C001C_MISSING_BC';

  if (areMissingBarcodePotential) {
    if (
      barcodeMatchLogic === 'Set intersection not empty' ||
      barcodeMatchLogic === 'WMS barcodes are superset'
    ) {
      return theme.palette.grey[500];
    }
    return theme.palette.warning.main;
  }

  switch (barcodeMatchLogic) {
    case 'Set exact match':
    case 'Verity barcodes are superset':
      return theme.palette.error.main;
    case 'WMS barcodes are superset':
      return theme.palette.grey[500];
    case 'Set intersection not empty':
      return theme.palette.grey[500];
    default:
      return theme.palette.error.main;
  }
};

export const getUnexpectedColor = (
  barcodeMatchLogic: BarcodeMatchLogic,
  issueLogic: IRuleActionSTIssueLogicEnum | null = null,
  theme: Theme,
) => {
  const areUnexpectedErrors = issueLogic === 'C001C_MISSING_BC';

  if (areUnexpectedErrors) {
    if (
      barcodeMatchLogic === 'Set intersection not empty' ||
      barcodeMatchLogic === 'WMS barcodes are superset'
    ) {
      return theme.palette.grey[500];
    }
    return theme.palette.error.main;
  }

  switch (barcodeMatchLogic) {
    case 'Set exact match':
    case 'WMS barcodes are superset':
      return theme.palette.error.main;
    case 'Verity barcodes are superset':
      return theme.palette.grey[500];
    case 'Set intersection not empty':
      return theme.palette.grey[500];
    default:
      return theme.palette.warning.main;
  }
};

export const getBarcodeColor = (
  status: barcodeStatusEnum,
  barcodeMatchLogic: BarcodeMatchLogic = null,
  issueLogic: IRuleActionSTIssueLogicEnum | null = null,
  theme: Theme,
) => {
  const isPictureOnly = issueLogic === 'PICTURE_ONLY';
  if (isPictureOnly) {
    return theme.palette.grey[500];
  }

  if (status === barcodeStatusEnum.Match) {
    return theme.palette.success.main;
  }

  if (!barcodeMatchLogic) {
    return theme.palette.error.main;
  }
  if (status === barcodeStatusEnum.Missing) {
    return getMissingColor(barcodeMatchLogic, issueLogic, theme);
  }
  if (status === barcodeStatusEnum.Unexpected) {
    return getUnexpectedColor(barcodeMatchLogic, issueLogic, theme);
  }

  return theme.palette.grey[500];
};
