import { Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { makeStyles } from 'tss-react/mui';
import { DroneIcon } from 'components/common/FacilityMenu/Icons/DroneIcon';
import { TooltipedIcon } from 'components/common/TooltipedIcon';
import { IFleetStatusSummaryST } from 'codegen/flight_domain';
import { DroneOnChargerIcon } from '../../Icons/DroneOnChargerIcon';
import { TooltipTypes } from '../../../../store/GroundControl/IGroundControlStore';
import {
  numberOfDronesFlying,
  numberOfDronesLandedInPlace,
  numberOfDronesOnCharger,
} from './FleetStatus.utils';

interface FleetStatusProps {
  webSocketError: boolean;
  fleet_status_summary: IFleetStatusSummaryST | null | undefined;
}

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(3),
    color: theme.palette.primary.contrastText,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export const FleetStatus = ({ webSocketError, fleet_status_summary }: FleetStatusProps) => {
  const { classes } = useStyles();

  const hasNoData = !fleet_status_summary || webSocketError;
  const flying = hasNoData ? '-' : numberOfDronesFlying(fleet_status_summary);
  const onCharger = hasNoData ? '-' : numberOfDronesOnCharger(fleet_status_summary);
  const landedInPlace = hasNoData ? '-' : numberOfDronesLandedInPlace(fleet_status_summary);

  return (
    <div className={classes.wrapper}>
      <div className={classes.item}>
        <TooltipedIcon
          noMargin
          tooltip={TooltipTypes.FLYING}
          icon={<DroneIcon color="currentColor" />}
        />
        <Typography data-testid="c-n-drones-flying" variant="body2" color="inherit">
          {flying}
        </Typography>
      </div>

      <div className={classes.item}>
        <TooltipedIcon
          noMargin
          tooltip={TooltipTypes.ON_CHARGERS}
          icon={
            <DroneOnChargerIcon
              isActive={true}
              colorActive="currentColor"
              colorInactive="currentColor"
            />
          }
        />
        <Typography data-testid="c-n-drones-on-charger" variant="body2" color="inherit">
          {onCharger}
        </Typography>
      </div>

      <div className={classes.item}>
        <TooltipedIcon
          noMargin
          tooltip={TooltipTypes.ERRORS}
          icon={<WarningAmberIcon sx={{ color: '#fff' }} />}
        />
        <Typography data-testid="c-n-drones-displaced" variant="body2" color="inherit">
          {landedInPlace}
        </Typography>
      </div>
    </div>
  );
};
