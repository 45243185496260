import { GridValueGetter } from '@mui/x-data-grid-premium';
import { ILocationDataST, IVeritySlotStatusST } from 'codegen/warehouse_status';

export const emptyCellString = '-';

export const amendedValueGetter: GridValueGetter<ILocationDataST, boolean> = (value, row) => {
  const verityStatus = row.verity_status as IVeritySlotStatusST;

  return (
    verityStatus && verityStatus.user_overrides !== null && verityStatus.user_overrides?.length > 0
  );
};
