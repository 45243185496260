import { ILocationDataForReview1ST, IIssue1ST, ILocationData1ST } from 'codegen/report';
import { getRowForLocationsToReviewTable } from 'common/functions/locationRows/getRowForLocationsToReviewTable';
import { ILocationReportData } from 'udb/inventory/features/warehouse-status/warehouseStatusFunctions';

export const getLocationToReview = (
  locationsToReviewData: Record<string, ILocationDataForReview1ST>,
  issues: { [key: string]: Array<IIssue1ST> },
) => {
  const locationsToReview: ILocationReportData[] = [];

  Object.entries(locationsToReviewData).forEach(([location, locationData]) => {
    const rowData = getRowForLocationsToReviewTable(
      location,
      locationData as ILocationData1ST,
      issues[location],
    );
    locationsToReview.push(rowData);
  });

  return locationsToReview;
};
