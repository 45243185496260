import { ILocationDataST } from 'codegen/warehouse_status';
import { useMemo } from 'react';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { blue } from '@mui/material/colors';
import { DISPLAY_ISSUE_TYPES } from 'common/issueTypesAndStates';
import { getCoverage } from './utils/getLocationDataStats';
import { WarehouseStatsWidget } from './WarehouseStatsWidget';
import { getDataSerie } from './utils/getDataSeries';

export const WarehouseCoverage = ({
  locationData,
  onGridStateSelect,
}: {
  locationData?: ILocationDataST[];
  onGridStateSelect?: (state: GridInitialStatePremium) => void;
}) => {
  const coverage = useMemo(() => (locationData ? getCoverage(locationData) : null), [locationData]);

  const counter = (coverage?.excluded || 0) + (coverage?.included || 0);

  const neverScanned = coverage?.wmsOnly || 0 + (coverage?.noWmsNoVerity || 0);

  const data = [
    getDataSerie(
      0,
      coverage?.upToDate,
      'Up to date',
      'Locations which have been scanned after the latest WMS update',
      [
        {
          field: 'collection_delay',
          operator: '>=',
          value: '0',
        },
        {
          field: 'exclusion_status',
          operator: 'is',
          value: 'false',
        },
        {
          field: 'wms_status.state',
          operator: 'isNotEmpty',
        },
        {
          field: 'verity_status.collected_at',
          operator: 'isNotEmpty',
        },
        {
          field: 'issues.0.type',
          operator: 'notContains',
          value: DISPLAY_ISSUE_TYPES.INCONCLUSIVE4_W_INVALID,
        },
      ],
    ),
    getDataSerie(
      1,
      coverage?.staleData,
      'Out of date',
      'Locations whose drone scans are older than the latest WMS update',
      [
        {
          field: 'collection_delay',
          operator: '<',
          value: '0',
        },
        {
          field: 'exclusion_status',
          operator: 'is',
          value: 'false',
        },
        {
          field: 'wms_status.state',
          operator: 'isNotEmpty',
        },
        {
          field: 'verity_status.collected_at',
          operator: 'isNotEmpty',
        },
        {
          field: 'issues.0.type',
          operator: 'notContains',
          value: DISPLAY_ISSUE_TYPES.INCONCLUSIVE4_W_INVALID,
        },
      ],
    ),
    getDataSerie(
      2,
      coverage?.verityOnly,
      'No WMS data',
      'Locations which have been scanned, but for which there is no WMS data available',
      [
        {
          field: 'wms_status.state',
          operator: 'isEmpty',
        },
        {
          field: 'verity_status.collected_at',
          operator: 'isNotEmpty',
        },
        {
          field: 'exclusion_status',
          operator: 'is',
          value: 'false',
        },
        {
          field: 'issues.0.type',
          operator: 'notContains',
          value: DISPLAY_ISSUE_TYPES.INCONCLUSIVE4_W_INVALID,
        },
      ],
    ),
    getDataSerie(3, neverScanned, 'Never visited', 'Locations which have never been scanned', [
      {
        field: 'verity_status.collected_at',
        operator: 'isEmpty',
      },
      {
        field: 'exclusion_status',
        operator: 'is',
        value: 'false',
      },
      {
        field: 'issues.0.type',
        operator: 'notContains',
        value: DISPLAY_ISSUE_TYPES.INCONCLUSIVE4_W_INVALID,
      },
    ]),
    getDataSerie(
      4,
      coverage?.excluded,
      'Excluded',
      'Location which have been excluded from scanning by the user',
      [
        {
          field: 'exclusion_status',
          operator: 'is',
          value: 'true',
        },
      ],
    ),
    getDataSerie(5, coverage?.invalid, 'Invalid', 'Location for which the WMS data is not valid', [
      {
        field: 'issues.0.type',
        operator: 'contains',
        value: DISPLAY_ISSUE_TYPES.INCONCLUSIVE4_W_INVALID,
      },
      {
        field: 'exclusion_status',
        operator: 'is',
        value: 'false',
      },
    ]),
  ];

  const colors = [blue[900], blue[700], blue[500], blue.A200, blue[400], blue.A700];

  return (
    <WarehouseStatsWidget
      id="coverage"
      title="Coverage"
      unit="locations"
      unitPrefix="mapped"
      data={data}
      counter={counter}
      colors={colors}
      cols={6}
      onGridStateSelect={onGridStateSelect}
    />
  );
};
