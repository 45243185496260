import { ReportSummaryData } from 'udb/inventory/features/reports/reducer/report-store/ReportStore.model';
import { IssueSummary, IssueSummaryCard } from './issue-summary-card/IssueSummaryCard';
import { ReportSummaryCard } from './report-summary-card/ReportSummaryCard';

export const ReportOverview = ({
  summarySpinner,
  issuesSpinner,
  issuesSummary,
  canReviewReport,
  reportNeedsReview,
  reportLocationData,
  reportExecutionData,
}: {
  summarySpinner: number;
  issuesSpinner: number;
  issuesSummary: IssueSummary;
  canReviewReport: boolean;
  reportNeedsReview: boolean;
  reportLocationData: ReportSummaryData;
  reportExecutionData: ReportSummaryData;
}) => (
  <>
    <ReportSummaryCard
      cardFor="summary data"
      isLoading={summarySpinner}
      reportSummaryData={reportLocationData}
    />

    <ReportSummaryCard
      cardFor="execution data"
      isLoading={summarySpinner}
      reportSummaryData={reportExecutionData}
    />

    {(!canReviewReport || !reportNeedsReview) && (
      <IssueSummaryCard isLoading={!!issuesSpinner} issuesSummary={issuesSummary} />
    )}
  </>
);
