import { useEffect, useRef, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { getLogPrefixForType } from 'common/functions/logFunctions';
import {
  AUTH_PAGES_URLS,
  FACILITY_SETTINGS_PAGES_URLS,
  WARNING_PAGES_URLS,
  NON_CONFIGURED_FACILITY_PAGE_URLS,
} from 'common/pages';
import { useFacilityLevelStore } from '../store/FacilityLevelStore/facilityLevelStore';
import { useClientLevelStore } from '../store/ClientLevelStore/clientLevelStore';
import { useRequestController } from '.';
import { FacilityActionNames } from '../store/FacilityLevelStore/facilityLevelActions';

/**
 * Hook that ensures that the data for the currently selected facility is loaded.
 * @param componentName Name of the component making use of the hook.
 * @returns a flag which is true if the facility data has been loaded and is ready to use.
 */
export const useLoadFacilityLevelData = (componentName: string) => {
  const { systemId = '' } = useParams();
  const sysIdFromUrl = systemId || '';

  const {
    stateFacilityLevel,
    dispatchFacilityLevel,
    deleteFacilitySpecificData,
    awaitGetFacilitySpecificData,
    isFacilityConfigured,
    isDataReady,
  } = useFacilityLevelStore();

  const { stateClientLevel } = useClientLevelStore();

  const location = useLocation();

  const isLoading = useRef(false);
  /**
   * String to prefix console logs with.
   */
  const logPrefix = getLogPrefixForType('HOOK', 'useLoadFacilityLevelData', componentName);
  /**
   * get request controller object to be used e.g. for canceling pending network requests.
   */
  const { requestController } = useRequestController(logPrefix);

  const redirectTo = useCallback(
    /**
     * Function which redirects to the given URL.
     * @param url URL the browser will be redirected to.
     */
    (url: string) => {
      const lp = getLogPrefixForType('FUNCTION', 'redirectTo', logPrefix);
      console.debug(lp, `redirect from: ${location.pathname} to: ${url}`);
      // Here we're using a replace instead of Navigate for we want to substitute the
      // location in the history so, that if a user navigates back doesn't end in a loop.
      window.location.replace(url);
    },
    [location.pathname, logPrefix],
  );

  /**
   * Load facility specific data
   * @params system ID
   * @returns a promise which will resolve if loading the data is successful.
   */
  const getSpecificFacilityData = useCallback(
    async (systemId: string) => {
      const lp = getLogPrefixForType('FUNCTION', 'getSpecificFacilityData', logPrefix);

      try {
        console.debug(lp, 'deleting facility specific data...');

        await deleteFacilitySpecificData(componentName);

        console.debug(lp, 'facility specific data was deleted');
        console.debug(lp, `getting facility ${systemId} specific data...`);

        await awaitGetFacilitySpecificData(requestController, systemId);

        console.debug(lp, 'facility specific data was gotten');
      } catch (error) {
        console.warn(lp, `there was an error retrieving data: ${error}`);
        redirectTo(WARNING_PAGES_URLS.NOT_FOUND);
        throw error;
      }

      console.debug(lp, `data for systemId: ${systemId} => (successfully) LOADED`);
    },
    [
      awaitGetFacilitySpecificData,
      componentName,
      deleteFacilitySpecificData,
      logPrefix,
      redirectTo,
      requestController,
    ],
  );

  const isValidSystemId = useCallback(
    /**
     * Verity whether the given system ID correspond to a valid (accessible) facility
     * @param systemId system ID
     * @returns true if the System ID is valid
     */
    (systemId: string) => stateClientLevel.facilityList.map((f) => f.id).includes(systemId),
    [stateClientLevel.facilityList],
  );

  useEffect(
    /**
     * This useEffect is used to load the data into the store
     */
    () => {
      const load = async () => {
        isLoading.current = true;
        await getSpecificFacilityData(sysIdFromUrl);
        isLoading.current = false;
      };

      let isReloadRequired = false;

      if (isLoading.current) {
        console.debug(logPrefix, `system id in the URL ${sysIdFromUrl} is already loading => skip`);
        isReloadRequired = false;
      } else if (!stateFacilityLevel.currentSystemId) {
        console.debug(logPrefix, 'no facility data loaded => load');
        isReloadRequired = true;
      } else if (
        stateFacilityLevel.currentSystemId &&
        stateFacilityLevel.currentSystemId !== sysIdFromUrl
      ) {
        console.debug(
          logPrefix,
          `system id in the URL ${sysIdFromUrl} differs form system id from store ${stateFacilityLevel.currentSystemId} => reload`,
        );
        isReloadRequired = true;
      }

      if (isReloadRequired && isValidSystemId(sysIdFromUrl)) {
        // (re-)load the facility specific data.
        console.debug(logPrefix, `System ID: ${sysIdFromUrl} is valid.`);
        load();
      }
    },
    [
      getSpecificFacilityData,
      isValidSystemId,
      logPrefix,
      stateFacilityLevel.currentSystemId,
      sysIdFromUrl,
    ],
  );

  useEffect(
    /**
     * This hooks deal with the redirect (if needed)
     */
    () => {
      if (!isFacilityConfigured(systemId)) {
        console.debug(logPrefix, `facility Not Setup (systemId: ${systemId})`);
        if (systemId) {
          // Note: there are the pages which can be legitimately opened for a System ID,
          // who has not been set yet for a facility.
          // For these reason we need to not redirect them (in particular:
          // - "system details" which would otherwise be not reachable)
          // - facility details
          // - "set mfa preference"
          // - "no facility warning"
          //
          // Note 2: we're back into the business of treating redirection exclusion explicitly
          // which is un-elegant to say the least.

          // If the systemId from the url doesn't match any facilities id in clientLevelStore
          // then redirect user to Not-Found page
          if (!isValidSystemId(systemId)) {
            redirectTo(WARNING_PAGES_URLS.NOT_FOUND);
          }

          const url = location.pathname;

          const isReservedRoute = NON_CONFIGURED_FACILITY_PAGE_URLS(systemId).some((route) =>
            url.includes(route),
          );

          if (!isReservedRoute) {
            redirectTo(`/${systemId}${FACILITY_SETTINGS_PAGES_URLS.FACILITY_DETAILS}`);
          }
        } else {
          // Note: you shouldn't end up here: this is a hook for facility level pages and, hence
          // a systemId should always be present.
          // In case a user somehow does, then we sign him out.
          console.warn(
            logPrefix,
            `Trying to load ${window.location.href} with no system Id => sign out.`,
          );

          redirectTo(AUTH_PAGES_URLS.SIGNOUT);
        }
      }
    },
    [isFacilityConfigured, isValidSystemId, location.pathname, logPrefix, redirectTo, systemId],
  );

  useEffect(() => {
    !stateFacilityLevel.isFacilityDataInitiated &&
      dispatchFacilityLevel({
        type: FacilityActionNames.SET_IS_FACILITY_DATA_INITIATED,
        payload: isDataReady(systemId),
      });
  }, [dispatchFacilityLevel, stateFacilityLevel.isFacilityDataInitiated, isDataReady, systemId]);
};
