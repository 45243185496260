import { GridValueGetter } from '@mui/x-data-grid';
import { ILocationDataST } from 'codegen/warehouse_status';
import { getUnknownBarcodes } from '../barcodeFunctions';

export const unknownBarcodesValueGetter: GridValueGetter<ILocationDataST> = (
  value,
  row,
): number => {
  const unknown = getUnknownBarcodes(row);
  return unknown ? unknown.length : 0;
};
