import isEmpty from 'lodash/isEmpty';
import ImageIcon from '@mui/icons-material/Image';
import { IIssueST, ILocationDataST, ILocationDataSTStateEnum } from 'codegen/report';
import { ILocationReportData } from 'udb/inventory/features/warehouse-status/warehouseStatusFunctions';
import { DISPLAY_ISSUE_TYPES } from '../../issueTypesAndStates';
import {
  getVerityDateFromSlotStatus,
  getUserNameFromSlotStatus,
  getUserValueFromSlotStatus,
} from '../slot/utils/veritySlotFunctions';
import { getVerityValueFromSlotStatus } from '../slot/utils/getVerityValueFromSlotStatus';
import { getPaddedSlotContent } from '../slot/utils/getPaddedSlotContent';
import {
  getIssueTypeForFullReportTable,
  getWmsBarcodeLocationAccordingToVerityFromSingleIssue,
  getVerityBarcodeLocationAccordingToWMSFromSingleIssue,
  getLocationIssueType,
} from '../issues/issueFunctions';
import { getTableRowActionsForSlotStatus } from './getTableRowActionsForSlotStatus';
import {
  getSlotUsageBlockedStatus,
  getWMSDateFromSlotStatus,
  getWMSSlotStatusVersionFromSlotStatus,
} from '../slot/utils/wmsSlotFunctions';

/**
 * Get the data needed to render a location row
 */
export const getTableRowForLocation = (
  location: string,
  locationData: ILocationDataST,
  issue: IIssueST,
  scanState?: ILocationDataSTStateEnum,
): ILocationReportData => {
  const rowData: ILocationReportData = {} as ILocationReportData;
  const slotContent = getPaddedSlotContent(
    locationData.wms_status,
    locationData.verity_status,
    scanState,
  );

  rowData.location = location;
  rowData.slotBlockedUsage = getSlotUsageBlockedStatus(locationData.wms_status);

  rowData.id = !isEmpty(issue) // TO REMOVE:: once we remove the issue page completely
    ? `${issue.issue_id}?version=${issue.version}`
    : location;
  rowData.issueId = !isEmpty(issue) ? `${issue.issue_id}` : '';

  rowData.issue = getIssueTypeForFullReportTable(issue.state, issue.type);
  rowData.issueType = !isEmpty(issue) ? DISPLAY_ISSUE_TYPES[issue.type] : 'None';
  rowData.issueFirstFoundOn = !isEmpty(issue) ? issue.first_found_on : '-';
  rowData.issueStatus = issue.state;

  rowData.contentExpected = slotContent.paddedContentExpected;
  rowData.wmsValue = slotContent.paddedContentExpected;
  rowData.colorizeContentExpected = slotContent.colorizeContentExpected;

  rowData.wmsDate = getWMSDateFromSlotStatus(locationData.wms_status);

  rowData.wmsCustomer = slotContent.paddedCustomer;
  rowData.wmsArticleNo = slotContent.paddedArticleNumber;
  rowData.wmsQty = slotContent.paddedQuantity;
  rowData.wmsSlotStatusVersion = getWMSSlotStatusVersionFromSlotStatus(locationData.wms_status);

  rowData.barcodeExpectedFoundOn =
    !isEmpty(issue) && issue.state !== 'SOLVED'
      ? getWmsBarcodeLocationAccordingToVerityFromSingleIssue(issue)
      : '-';
  rowData.barcodeFoundShouldBeOn = !isEmpty(issue)
    ? getVerityBarcodeLocationAccordingToWMSFromSingleIssue(issue)
    : '-';

  rowData.contentFound = slotContent.paddedContentFound;
  rowData.contentFoundDate = slotContent.contentFoundDate;
  rowData.contentFoundChangedByTheClient = slotContent.contentFoundChangedByTheClient;
  rowData.colorizeContentFound = slotContent.colorizeContentFound;

  rowData.matchArray = slotContent.matchArray;

  rowData.verityValue = getVerityValueFromSlotStatus(locationData.verity_status);
  rowData.verityDate = getVerityDateFromSlotStatus(locationData.verity_status);

  rowData.userValue = slotContent.contentFoundChangedByTheClient
    ? slotContent.paddedContentFound
    : getUserValueFromSlotStatus(locationData.verity_status);
  rowData.userName = getUserNameFromSlotStatus(locationData.verity_status);

  rowData.locationIssueType = getLocationIssueType(
    issue.state,
    rowData.verityDate,
    rowData.wmsDate,
  );

  rowData.issueStatus = !isEmpty(issue) ? issue.state : 'NONE';
  rowData.image = <ImageIcon />;

  // TR::2020-01-06:: FIX ME, allow all entries to redirect to a location page
  rowData.allowRedirection = !isEmpty(issue);

  rowData.actions = getTableRowActionsForSlotStatus(locationData, issue, rowData);

  rowData.slotStatus = locationData;

  return rowData;
};
