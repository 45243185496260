import { GridValueGetter } from '@mui/x-data-grid';
import { ILocationDataST } from 'codegen/warehouse_status';
import { getExpectedBarcodes } from '../barcodeFunctions';

export const expectedBarcodesValueGetter: GridValueGetter<ILocationDataST> = (
  value,
  row,
): number => {
  const expected = getExpectedBarcodes(row);
  return expected ? expected.length : 0;
};
