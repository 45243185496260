import { GridValueGetter } from '@mui/x-data-grid';
import { ILocationDataST } from 'codegen/warehouse_status';

export const wmsDateValueGetter: GridValueGetter<ILocationDataST, Date | null> = (value, row) => {
  const dateTime = typeof row.wms_status !== 'string' && row.wms_status?.changed_at;
  if (!dateTime) {
    return null;
  }
  return new Date(dateTime);
};
