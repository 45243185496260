import { Dispatch } from 'react';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { getLocationListData } from 'common/Tables/tableFunctions';
import { OrderValue } from 'components/EnhancedTable/types/rows';
import { filterOrderTableRows } from 'components/EnhancedTable/functions/filterOrderTableRows';
import { HeadCellProp } from 'components/EnhancedTable/types/cell';
import { IFacilityModalsAction } from 'store/Modals/facilityModals/IFacilityModalsActions';
import { FacilityModalsActionTypes } from 'store/Modals/types';
import { ILocationReportData } from 'udb/inventory/features/warehouse-status/warehouseStatusFunctions';

const logPrefix = getLogPrefixForType('FUNCTION', 'handleLocationModalOpening');

type HandleLocationModal = {
  isModalOpened: boolean;
  rows: ILocationReportData[];
  searchParams: URLSearchParams;
  headCells: HeadCellProp[];
  dispatchFacilityModals: Dispatch<IFacilityModalsAction>;
  refreshData: (reportId: string, nLocationsPerRequest: number) => void;
  parentPage?: 'WarehouseStatus' | 'Report';
  reportId?: string;
};

/**
 * This function is used for opening location modal if location param is present in url
 * @param params HandleLocationModal
 */
export const handleLocationModalOpening = ({
  isModalOpened,
  rows,
  searchParams,
  headCells,
  dispatchFacilityModals,
  refreshData,
  parentPage,
  reportId,
}: HandleLocationModal) => {
  const searchTerm = searchParams.get('searchTerm') || '';
  const orderBy = (searchParams.get('orderBy') as keyof ILocationReportData) || '';
  const order = (searchParams.get('order') as OrderValue) || '';
  const location = searchParams.get('location') || '';

  const filteredRows = filterOrderTableRows(rows, searchTerm, orderBy, order, headCells);
  const row = filteredRows.find((row: any) => row.location === location);

  if (location && !isModalOpened) {
    console.debug(
      logPrefix,
      'useEffect =>',
      `opening Location modal for location: ${location}, from url params`,
    );

    if (!row) {
      console.debug(
        logPrefix,
        'useEffect =>',
        `location: ${location}, from url params, was not found`,
      );
    }

    // used to open location dialog
    dispatchFacilityModals({
      type: FacilityModalsActionTypes.TOGGLE_LOCATION_MODAL,
      payload: row && getLocationListData(filteredRows, row),
      refreshDataOnClose: refreshData,
      parentPage,
      reportId,
    });
  }
};
