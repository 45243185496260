import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { isEmpty } from 'lodash';
import {
  IIssueST,
  IIssueSTStateEnum,
  IIssueSTTypeEnum,
  ILocationDataST,
  IVeritySlotStatusStateST,
  IVeritySlotStatusUserOverrideST,
  IWMSSlotStatusSTStateEnum,
} from 'codegen/report';
import { ILocationReportData } from 'udb/inventory/features/warehouse-status/warehouseStatusFunctions';
import { getSnoozeActionLabel, isSnoozedActionDisabled } from '../issues/issueFunctions';
import {
  getVerityDateFromSlotStatus,
  getUserNameFromSlotStatus,
  getVeritySlotStatusVersionFromSlotStatus,
  getVerityStateFromSlotStatus,
  getImageIdsFromSlotStatus,
  getUserValueFromSlotStatus,
  getOriginalVerityState,
  getOriginalVerityValue,
} from '../slot/utils/veritySlotFunctions';
import { getVerityValueFromSlotStatus } from '../slot/utils/getVerityValueFromSlotStatus';
import {
  getWMSStateFromSlotStatus,
  getWMSDateFromSlotStatus,
  getWMSArticleNosFromSlotStatus,
  getWMSQtyFromSlotStatus,
  getCustomersFromSlotStatus,
  getWMSSlotStatusVersionFromSlotStatus,
  getWMSValueFromSlotStatus,
} from '../slot/utils/wmsSlotFunctions';
import { getLatestOverwriteFromSlotStatus } from '../slot/utils/getLatestOverwriteFromSlotStatus';

export const getRowActions = (issue: IIssueST) => {
  if (userHasPermission(PERMISSION.SNOOZE_LOCATION)) {
    return [
      {
        label: getSnoozeActionLabel(issue.state),
        disabled: isEmpty(issue) || isSnoozedActionDisabled(issue),
      },
    ];
  }
  return [];
};

export type ActionsSlotData = {
  id: string;
  version?: number;
  state: IIssueSTStateEnum;
  issueType: IIssueSTTypeEnum;
  location?: string;
  slotStatusVersion: number | '-';
  latestSlotVersion: number | '-';
  wmsValue: string;
  wmsState: '-' | IWMSSlotStatusSTStateEnum;
  wmsDate: string;
  wmsArticleNo: string[];
  wmsQty: string[];
  wmsCustomer: string[];
  wmsSlotStatusVersion: number | null;
  originalVerityValue: string;
  originalVerityState: string;
  verityValue: string;
  verityState: '-' | IVeritySlotStatusStateST;
  verityDate: string;
  userOverride?: IVeritySlotStatusUserOverrideST;
  userOverrideValue: string[];
  userOverrideUserName: string;
  imageIds: string[];
  snoozedByUserId?: string | null;
  snoozedByUserName?: string | null;
  snoozedOn?: string | null;
  snoozedUntil?: string | null;
  rowData: ILocationReportData;
};

const getActionsSlotData = (
  slotStatus: ILocationDataST,
  locationIssue: IIssueST,
  rowData: ILocationReportData,
): ActionsSlotData => ({
  id: locationIssue && locationIssue.issue_id,
  version: locationIssue && locationIssue.version,
  state: locationIssue && locationIssue.state,
  issueType: locationIssue && locationIssue.type,

  location: slotStatus.slot_label,

  slotStatusVersion: getVeritySlotStatusVersionFromSlotStatus(slotStatus.verity_status),
  latestSlotVersion: getVeritySlotStatusVersionFromSlotStatus(slotStatus.verity_status), // TR:: I left this here for retro-compatibility reasons

  wmsValue: getWMSValueFromSlotStatus(slotStatus.wms_status).contentExpectedValues.join(', '), // sent as a string to allow amend
  wmsState: getWMSStateFromSlotStatus(slotStatus.wms_status),
  wmsDate: getWMSDateFromSlotStatus(slotStatus.wms_status),
  wmsArticleNo: getWMSArticleNosFromSlotStatus(slotStatus.wms_status),
  wmsQty: getWMSQtyFromSlotStatus(slotStatus.wms_status),
  wmsCustomer: getCustomersFromSlotStatus(slotStatus.wms_status),
  wmsSlotStatusVersion: getWMSSlotStatusVersionFromSlotStatus(slotStatus.wms_status),

  originalVerityValue: getOriginalVerityValue(slotStatus.verity_status).join(', '), // sent as a string to allow amend
  originalVerityState: getOriginalVerityState(slotStatus.verity_status),
  verityValue: getVerityValueFromSlotStatus(slotStatus.verity_status).join(', '), // sent as a string to allow amend
  verityState: getVerityStateFromSlotStatus(slotStatus.verity_status),
  verityDate: getVerityDateFromSlotStatus(slotStatus.verity_status),

  userOverride: getLatestOverwriteFromSlotStatus(slotStatus.verity_status, 'client'),
  userOverrideValue: getUserValueFromSlotStatus(slotStatus.verity_status),
  userOverrideUserName: getUserNameFromSlotStatus(slotStatus.verity_status),

  imageIds: getImageIdsFromSlotStatus(slotStatus.verity_status),

  snoozedByUserId: locationIssue && locationIssue.snoozed_by_user_id,
  snoozedByUserName: locationIssue && locationIssue.snoozed_by_user_name,
  snoozedOn: locationIssue && locationIssue.snoozed_on,
  snoozedUntil: locationIssue && locationIssue.snoozed_until,

  rowData,
});

/**
 * Get the data needed to execute actions on a location / issue
 * used on actions and on the location modal
 * @param slotStatus
 * @param locationIssue
 * @param rowData
 * @param permissions
 * @returns
 */
export const getTableRowActionsForSlotStatus = (
  slotStatus: ILocationDataST,
  locationIssue: IIssueST,
  rowData: ILocationReportData,
) => {
  const rowActions = getRowActions(locationIssue);
  return {
    data: getActionsSlotData(slotStatus, locationIssue, rowData),
    actions: rowActions,
  };
};
