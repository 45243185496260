import { notEver } from 'common/functions/otherFunctions';
import { ReportContainerActions, ReportContainerActionNames } from './ReportContainerActions';
import { reportContainerInitialState, ReportContainerState } from './ReportContainerState';

export const reportContainerReducer = (
  state: ReportContainerState,
  action: ReportContainerActions,
): ReportContainerState => {
  switch (action.type) {
    case ReportContainerActionNames.CLEAR_STATE: {
      return {
        ...state,
        amended: reportContainerInitialState.amended,
        snoozed: reportContainerInitialState.snoozed,
        potential: reportContainerInitialState.potential,
        inconclusive: reportContainerInitialState.inconclusive,
        invalid: reportContainerInitialState.invalid,
        fullReport: reportContainerInitialState.fullReport,
        locationsToReview: reportContainerInitialState.locationsToReview,
        requestsToSend: reportContainerInitialState.requestsToSend,
        repliesReceived: reportContainerInitialState.repliesReceived,
        allRequestsFinished: reportContainerInitialState.allRequestsFinished,
      };
    }
    case ReportContainerActionNames.SET_ANY: {
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      };
    }
    case ReportContainerActionNames.ALL_REQUESTS_FINISHED: {
      return {
        ...state,
        allRequestsFinished: true,
        displayRefreshOption: state.reportNeedsReview && state.locationsToReview.length === 0,
        tableSpinner: false,
      };
    }
    case ReportContainerActionNames.GET_REPORT_SUMMARY_CALLBACK_BEFORE_SEND: {
      return {
        ...state,
        summaryLoaded: false,
        issuesSpinner: 1,
        summarySpinner: 1,
      };
    }
    case ReportContainerActionNames.GET_REPORT_SUMMARY_CALLBACK_SUCCESS: {
      return {
        ...state,
        reportLocationData: action.payload,
        reportNumLocations: action.payload.counter,
        summaryLoaded: true,
      };
    }
    case ReportContainerActionNames.GET_REPORT_EXECUTION_CALLBACK_SUCCESS: {
      return {
        ...state,
        reportExecutionData: action.payload,
      };
    }
    case ReportContainerActionNames.UPDATE_REPORT_PROGRESS: {
      const { reportId, reportProgress, reportStatus, reportUpdateId } = action.payload;
      if (!state.summaryLoaded || reportId !== reportUpdateId) return state;

      const { reportLocationData } = state;
      const reportSection = reportLocationData?.sections[0]?.sectionItems;
      const progressIndex = reportSection?.findIndex((r) => r.label === 'Progress');
      const stateIndex = reportSection?.findIndex((r) => r.label === 'State');

      if (progressIndex && progressIndex !== -1 && stateIndex && stateIndex !== -1) {
        reportSection[progressIndex].value = `${reportProgress}%`;
        reportSection[stateIndex].value = `${reportStatus}`;
        reportSection[stateIndex].value =
          typeof reportSection[stateIndex].value === 'string'
            ? (reportSection[stateIndex].value as string).toLowerCase()
            : reportSection[stateIndex].value;
      }
      const subtitle = `${reportStatus} - ${reportProgress}%`.toLowerCase();
      reportLocationData.subtitle = subtitle.charAt(0).toUpperCase() + subtitle.slice(1);
      return { ...state, reportLocationData: { ...reportLocationData } };
    }
    case ReportContainerActionNames.REPORT_LOCATIONS_EMPTY: {
      const { request } = action.payload;
      return {
        ...state,
        tableSpinner: false,
        issuesSpinner: 0,
        summarySpinner: 0,
        fullReportSpinner: 0,
        reportName: request.report_name,
        pageSubtitle: request.report_spec.report_spec_name,
        reportLoaded: true,
      };
    }
    case ReportContainerActionNames.GET_REPORT_CALLBACK_SUCCESS: {
      const {
        fullResponse,
        pageSubtitle,
        issuesSummary,
        issues,
        amended,
        snoozed,
        potential,
        inconclusive,
        invalid,
        fullReport,
        locationsToReview,
        reportNeedsReview,
        reportState,
        reportName,
      } = action.payload;
      return {
        ...state,
        rawReportData: fullResponse,
        pageSubtitle,
        reportName,
        reportState,
        issuesSummary,
        // We use the logic comparator || to compare with the previous state, assuring
        // that if one page of the request deems the report to need a review, then the
        // report needs a review, regardless of the content of the remaining pages
        reportNeedsReview: state.reportNeedsReview || reportNeedsReview,
        issues: [...state.issues, ...issues],
        amended: [...state.amended, ...amended],
        snoozed: [...state.snoozed, ...snoozed],
        potential: [...state.potential, ...potential],
        inconclusive: [...state.inconclusive, ...inconclusive],
        invalid: [...state.invalid, ...invalid],
        fullReport: [...state.fullReport, ...fullReport],
        locationsToReview: [...state.locationsToReview, ...locationsToReview],
        reportLoaded: true,
      };
    }
    case ReportContainerActionNames.GET_REPORT_CALLBACK_FINALLY: {
      return {
        ...state,
        fullReportSpinner: 0,
        issuesSpinner: 0,
        repliesReceived: state.repliesReceived + 1,
      };
    }

    default:
      notEver(action);
      return state;
  }
};
