import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { REPORT_STATES } from 'common/reportStates';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { handleActionClick } from 'common/Actions/actionHandlers';
import { ReportActionButton } from 'udb/inventory/features/reports/reducer/report-store/ReportStore';
import { IProcessReportSummariesData } from 'udb/inventory/features/reports/reducer/report-store/utils/processReportSummariesData.model';
import { ReportInCard } from 'udb/inventory/features/reports/reducer/report-store/reportInCard.model';
import { useFacilityModalsStore } from '../../store/Modals';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { useRequestController } from '../../hooks';

import { userHasPermission } from '../../features/permissions/userHasPermission';
import { PERMISSION } from '../../features/permissions/permissions.model';
import { OngoingReportsCard } from './OngoingReportsCard';
import { ToReviewReportsCard } from './ToReviewReportsCard';
import { getPendingReviewAndOngoingReports } from './functions/getToReviewAndOngoingReports';

interface IOngoingAndToReviewReportsCardsProps {
  toReviewTab: number;
  ongoingTab: number;
}

const logPrefix = getLogPrefixForType('PAGE', 'OngoingAndToReviewReportsCards');

export const OngoingAndToReviewReportsCards = ({
  toReviewTab,
  ongoingTab,
}: IOngoingAndToReviewReportsCardsProps) => {
  const { systemId = '' } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [reportsNoInfo, setReportsNoInfo] = useState({
    pendingReview: { numberOfReports: 0, lastUpdatedAt: '' },
    ongoing: { numberOfReports: 0, mostRecentStart: '' },
  });
  const [reports, setReports] = useState<{
    pendingReview: ReportInCard[];
    ongoing: ReportInCard[];
  }>({
    pendingReview: [],
    ongoing: [],
  });

  const { dispatchFacilityModals } = useFacilityModalsStore();
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { requestController } = useRequestController(logPrefix);

  const isVisibleOngoingCard = userHasPermission(PERMISSION.VIEW_ONGOING_REPORTS);
  const isVisibleReviewCard = userHasPermission(PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT);
  const reportId: string = stateFacilityLevel.inventory.reportUpdate?.data?.report_id;
  const status: REPORT_STATES = stateFacilityLevel.inventory.reportUpdate?.data?.report_state;
  const reportProgress: number = stateFacilityLevel.inventory.reportUpdate?.data?.progress;
  const reportCompletion: number = stateFacilityLevel.inventory.reportUpdate?.data?.completion;

  const updateReports = (reports: ReportInCard[]) =>
    reports.map((report: ReportInCard) => {
      if (report.reportId === reportId) {
        report.progress = reportProgress;
        report.status = status;
        report.completion = reportCompletion;
      }
      return report;
    });

  const mapReports = useCallback(
    (reports: ReportInCard[], handleReports: (r: IProcessReportSummariesData) => void) =>
      reports.map((report: ReportInCard) => {
        const optionsAux: ReportActionButton[] =
          report.actions?.actions?.map((action: any) => ({
            label: action.label,
            disabled: action.disabled,
            clickHandler: () =>
              handleActionClick(
                [report?.actions?.data],
                action.label,
                dispatchFacilityModals,
                {
                  refreshData: () =>
                    getPendingReviewAndOngoingReports({
                      requestController,
                      systemId,
                      setIsLoading,
                      onSuccess: handleReports,
                    }),
                },
                report?.actions?.data.report_id,
              ),
          })) || [];

        return {
          ...report,
          actionButtons: optionsAux,
        };
      }),
    [dispatchFacilityModals, requestController, systemId],
  );

  const handleReports = useCallback(
    (r: IProcessReportSummariesData) => {
      const { reportsInfo, toReviewReports, ongoingReports } = r;

      setReports({
        ongoing: mapReports(ongoingReports, handleReports),
        pendingReview: mapReports(toReviewReports, handleReports),
      });
      setReportsNoInfo({
        ongoing: reportsInfo.ongoingReports,
        pendingReview: reportsInfo.toReviewReports,
      });
    },
    [mapReports],
  );

  useEffect(
    () =>
      getPendingReviewAndOngoingReports({
        systemId,
        requestController,
        setIsLoading,
        onSuccess: handleReports,
      }),
    [requestController, handleReports, systemId],
  );

  const ongoingReports = updateReports(reports.ongoing);
  const pendingReviewReports = updateReports(reports.pendingReview);

  return (
    <>
      {isVisibleReviewCard && (
        <ToReviewReportsCard
          isLoading={isLoading}
          activeTab={toReviewTab}
          reports={pendingReviewReports}
          reportsNoInfo={reportsNoInfo.pendingReview}
        />
      )}

      {isVisibleOngoingCard && (
        <OngoingReportsCard
          isLoading={isLoading}
          activeTab={ongoingTab}
          reports={ongoingReports}
          reportsNoInfo={reportsNoInfo.ongoing}
        />
      )}
    </>
  );
};
