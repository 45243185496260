/* eslint-disable react-hooks/exhaustive-deps */
import { Children, useEffect } from 'react';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { MapOptionsModal } from './features/map-options-modal/MapOptionsModal';
import { MapRoot, NodeST } from './MapContainer.model';
import { apiServices as Api } from '../../services/Api';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { useMapStore } from './reducer/3DmapStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { IFlightDomainData } from '../../store/GroundControl/IGroundControlStore';
import { getMap3DService } from '../../services/services';

const removeAuthHeader = (data: any, headers: any) => {
  delete headers.Authorization;
  if (headers.common) delete headers.common.Authorization;
  return data;
};

/**
 * Gets the map from the server.
 * @param systemId: ID of the facility
 * @param flightDomainId: ID of the flight domain
 * @returns MapRoot
 */
const getMap = async (systemId: string, flightDomainId: string, mapVersion?: string) => {
  const mapInfo = flightDomainId
    ? await getMap3DService().getFlightDomainMap3d(systemId, flightDomainId)
    : await getMap3DService().getFacilityMap3d(systemId);

  if (mapInfo.data.version === mapVersion) {
    return null;
  }

  const mapResponse = await Api().get(mapInfo.data.url, { transformRequest: [removeAuthHeader] });
  const mapJSON =
    typeof mapResponse.data === 'string' ? JSON.parse(mapResponse.data) : mapResponse.data;

  return mapJSON;
};

/**
 * Returns name of current flight domain given a list of flight domains.
 * @param fds loaded flight domains
 * @returns name of current flight domain
 */
const useGetCurrentFlightDomainName = (
  flightDomainId: string,
  fds: IFlightDomainData[],
): string => {
  const flightDomainName = fds.find(
    (fd) => fd.flight_domain_id === flightDomainId,
  )?.flight_domain_name;
  return flightDomainName || 'no FD name found';
};

const logPrefix = getLogPrefixForType('COMPONENT', '3D MapContainer');

interface IMapContainerProps {
  systemId: string;
  flightDomainId: string;
  children: JSX.Element;
}

export const MapContainer = (props: IMapContainerProps) => {
  const { systemId, flightDomainId, children } = props;
  const { mapState, dispatchMapStore: mapDispatch } = useMapStore();
  const { flightDomains } = useFacilityLevelStore().stateFacilityLevel;
  const { options, showOptionsModal } = mapState;
  let { mapVersion } = mapState;

  const flightDomainName = useGetCurrentFlightDomainName(flightDomainId, flightDomains);

  useComponentDidMount(() => {
    const isSameFlightDomainName = mapState.map?.name === flightDomainName;
    mapVersion = !isSameFlightDomainName ? undefined : mapVersion;

    if (!isSameFlightDomainName) {
      console.debug(
        logPrefix,
        `map state holds ${mapState.map?.name} map that doesn't match current FD ${flightDomainName}. Setting map to null.`,
      );
      mapDispatch({ type: 'setMap', payload: { mapData: null, flightDomainName } });
    }

    getMap(systemId, flightDomainId, mapVersion)
      .then((mapData: MapRoot) => {
        if (mapData) {
          let flightDomainMap = mapData.nodes.find(
            (node) => node.name === flightDomainName,
          ) as NodeST;

          if (!flightDomainMap) {
            flightDomainMap = mapData.nodes[0] as NodeST;
            console.debug(
              `${logPrefix} no data found for flight domain ${flightDomainName} in ${mapData.name} ${mapData.version} map, using first flight domain from the map.`,
            );
          }
          console.debug(logPrefix, `map ${mapData.name} received and set.`);
          mapDispatch({
            type: 'setMap',
            payload: { mapData: flightDomainMap, mapVersion: mapData.version, flightDomainName },
          });
        }
      })
      .catch((err) => console.error(err));
  });

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'O' && e.altKey && e.shiftKey) {
        mapDispatch({ type: 'toggleOptionsModal' });
      }
    };
    window.addEventListener('keydown', listener);
    return () => window.removeEventListener('keydown', listener);
  }, [mapDispatch]);

  const shouldShowMap = mapState.map && !!mapState.flightDomainName;

  console.debug(logPrefix, `will ${shouldShowMap ? '' : 'not '}try to render map`);

  return (
    <>
      {shouldShowMap ? Children.map(Children.toArray(children), (c) => c) : null}

      {showOptionsModal && <MapOptionsModal options={options} mapDispatch={mapDispatch} />}
    </>
  );
};
