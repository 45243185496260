import { BinStatus } from 'shared/map-3d/bin3D/model/bin3DProps.model';
import { ReactNode } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import DoneIcon from '@mui/icons-material/Done';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';

interface LocationSummaryPopupBase {
  locationName: string;
  onClose: () => void;
  status: BinStatus;
  statusLabel?: string;
}

export interface LocationSummaryPopupDetail {
  title: string;
  color: 'error.main' | 'warning.main';
  items: string[];
}

interface LocationSummaryPopupWithDetails extends LocationSummaryPopupBase {
  status: 'POTENTIAL_ISSUE' | 'ISSUE';
  details: Array<LocationSummaryPopupDetail>;
}

interface LocationSummaryPopupNoDetails extends LocationSummaryPopupBase {
  status: 'MATCH' | 'EMPTY' | 'EXCLUDED' | 'NOT_SCANNED';
}

export type LocationSummaryPopupProps =
  | LocationSummaryPopupNoDetails
  | LocationSummaryPopupWithDetails;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never;

export type ExhaustiveObject<T extends string | number | symbol, V> = {
  [K in T]: V;
} & UnionToIntersection<T extends any ? { [P in T]: V } : never>;

export const ICON_MAP: ExhaustiveObject<BinStatus, ReactNode> = {
  ISSUE: <ErrorOutlineOutlinedIcon color="error" />,
  POTENTIAL_ISSUE: <WarningAmberIcon color="warning" />,
  MATCH: <DoneIcon color="success" />,
  EMPTY: <DoneIcon color="success" />,
  EXCLUDED: <StopCircleIcon color="action" />,
  NOT_SCANNED: <NoPhotographyIcon color="action" />,
};
