import { Box, Card, Container, LinearProgress } from '@mui/material';
import {
  DataGridPremium,
  GridInitialState,
  GridSlots,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { GridPremiumToolbarButton } from 'common/Tables/toolbar/GridPremiumToolbarButton';
import { CardGrid } from 'components/common/CardGrid/CardGrid';
import isEmpty from 'lodash/isEmpty';
import { ILocationReportData } from 'udb/inventory/features/warehouse-status/warehouseStatusFunctions';
import { SearchField } from 'udb/inventory/shared/premium-grid/features/search-field/SearchField';
import { cleanGridState } from 'udb/inventory/shared/premium-grid/utils/cleanGridState.util';
import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { useFacilityModalsStore } from 'store/Modals';
import { getGridRowHeight } from 'udb/inventory/shared/premium-grid/utils/getGridRowHeight.util';
import { handleRowClickEvent } from 'udb/inventory/shared/premium-grid/utils/handleRowClickEvent.util';
import { onLoadCustomGrid } from 'udb/inventory/shared/premium-grid/utils/onLoadCustomGrid.util';
import { onSaveCustomGrid } from 'udb/inventory/shared/premium-grid/utils/onSaveCustomGrid.util';
import { premiumGridStyles } from 'udb/inventory/shared/premium-grid/premiumGrid.styles';
import { getFilteredLocationData } from 'udb/inventory/shared/premium-grid/utils/getFilteredLocationData.util';
import { calculateTabCounts } from 'udb/inventory/shared/premium-grid/utils/calculateTabCounts';
import { ReportSummaryData } from '../../reducer/report-store/ReportStore.model';
import { useStyles } from './Report.styles';
import { ReportTableToolbar } from './ReportTableToolbar';
import { ReportTabs } from './ReportTabs';
import { getColumns } from './models/ReportColumns.model';
import { REPORT_TABS } from './models/ReportTabs.model';
import { serializedInitialGridState } from './defaults/serializedInitialGridState.default';
import { ReportLocationsGrid } from './report-locations-grid/ReportLocationsGrid';
import { ReportOverview } from './report-overview/ReportOverview';
import { IssueSummary } from './report-overview/issue-summary-card/IssueSummaryCard';
import { CurrentTab } from './utils/GetTabData';
import { searchFieldColumns } from './models/searchFieldColumns.model';

const STARTING_TAB = 0;

export const ReportPage = ({
  canReviewReport,
  reportNeedsReview,
  issuesSpinner,
  issuesSummary,
  summarySpinner,
  reportLocationData,
  reportExecutionData,
  fullReport,
  currentTabData,
  reportState,
  tabs,
  changeTab,
  getReportData,
  activeTabRLG,
  reviewLocationsTab,
  fullReportSpinner,
  tableSpinner,
  loadLocationData,
}: {
  canReviewReport: boolean;
  reportNeedsReview: boolean;
  issuesSpinner: number;
  issuesSummary: IssueSummary;
  summarySpinner: number;
  reportLocationData: ReportSummaryData;
  reportExecutionData: ReportSummaryData;
  currentTabData: CurrentTab;
  fullReport: ILocationReportData[];
  reportState: string;
  tabs: { icon: JSX.Element; label: string; show: boolean }[];
  getReportData: (reportId: string, nLocationsPerRequest: number) => void;
  activeTabRLG: number;
  reviewLocationsTab: number;
  fullReportSpinner: number;
  tableSpinner: boolean;
  changeTab: (tabValue: number) => void;
  loadLocationData: () => void;
}) => {
  const { reportId = '' } = useParams();
  const { cx, classes } = useStyles();

  const gridApiRef = useGridApiRef();
  const { dispatchFacilityModals } = useFacilityModalsStore();
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { facilitySettings } = stateFacilityLevel;
  const [searchParams, setSearchParams] = useSearchParams();
  const [serializedGridState, setSerializedGridState] = useState('');

  const dataGridStateFromURL = searchParams.get('dataGridState');
  const [activeTab, setActiveTab] = useState<number>(
    Number(searchParams.get('activeTab') ?? STARTING_TAB),
  );
  const [tabCounts, setTabCounts] = useState<{
    [key: string]: number;
  }>({});
  const [gridViewFromURL] = useState<GridInitialState>(
    dataGridStateFromURL ? JSON.parse(dataGridStateFromURL) : undefined,
  );

  const currentActiveSpinner: Record<string, boolean> = {
    issues: tableSpinner,
    'solved issues': tableSpinner,
    amended: tableSpinner,
    'snoozed issues': tableSpinner,
    'potential issues': tableSpinner,
    'inconclusive issues': tableSpinner,
    'invalid issues': tableSpinner,
    report: tableSpinner,
    'locations to review': tableSpinner,
  };

  const handleTabChange = (_event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
    setActiveTab(newValue);
    const { gridState } = REPORT_TABS[newValue];
    gridApiRef.current.restoreState({ ...gridState });

    searchParams.set('activeTab', newValue.toString());
    setSearchParams(searchParams);
  };

  const handleGridStateChange = () => {
    const currentGridState = cleanGridState(gridApiRef.current.exportState());
    const currentSerializedGridState = JSON.stringify(currentGridState);

    if (serializedGridState !== currentSerializedGridState) {
      setSerializedGridState(currentSerializedGridState);
    }
  };

  useEffect(() => {
    if (serializedGridState === serializedInitialGridState || serializedGridState === '') {
      return;
    }
    const urlState = searchParams.get('dataGridState');

    if (serializedGridState !== urlState) {
      searchParams.set('dataGridState', serializedGridState);
      setSearchParams(searchParams);
    }
  }, [serializedGridState, searchParams, setSearchParams]);

  const rowCount = gridApiRef.current?.getRowsCount && gridApiRef.current.getRowsCount();

  const toILocationDataSTMapper = (row: ILocationReportData) =>
    (row as ILocationReportData).slotStatus;

  useEffect(() => {
    setTabCounts(
      calculateTabCounts({
        locationDataList: fullReport.map(toILocationDataSTMapper),
        countAmended: true,
      }),
    );
  }, [fullReport, rowCount]);

  const columns = useMemo(() => getColumns(facilitySettings), [facilitySettings]);

  const rows = getFilteredLocationData(
    fullReport.map(toILocationDataSTMapper),
    REPORT_TABS[activeTab].issueTypeFilters,
    REPORT_TABS[activeTab].issueStateFilters,
    REPORT_TABS[activeTab].slotSettingFilter,
    REPORT_TABS[activeTab]?.filter,
  );

  const isLoading = tableSpinner || !!fullReportSpinner;

  return (
    <Container maxWidth="xl" sx={{ paddingTop: '32px' }}>
      <CardGrid columnCount={3} className="c-page-content">
        <ReportOverview
          summarySpinner={summarySpinner}
          issuesSpinner={issuesSpinner}
          issuesSummary={issuesSummary}
          canReviewReport={canReviewReport}
          reportNeedsReview={reportNeedsReview}
          reportLocationData={reportLocationData}
          reportExecutionData={reportExecutionData}
        />

        {!isEmpty(tabs) && (
          <Box gridColumn="1 / -1" component="div">
            {facilitySettings?.enable_experimental_report_grid ? (
              <Card
                sx={{
                  height: 'clamp(500px, 700px, calc(100vh - 200px))',
                }}
              >
                <div className={classes.cardHeader}>
                  <ReportTabs
                    tabs={REPORT_TABS}
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                    tabCounts={tabCounts}
                  />
                  <div className={cx(classes.cardHeaderSection, classes.cardHeaderActions)}>
                    <GridPremiumToolbarButton
                      name="SAVE VIEW"
                      tooltip="Save View"
                      onClick={() =>
                        onSaveCustomGrid({ gridName: 'report', activeTab, gridApiRef })
                      }
                      icon={<BookmarkAddIcon />}
                    />

                    <GridPremiumToolbarButton
                      name="LOAD VIEW"
                      tooltip="Load View"
                      disabled={!localStorage.getItem('savedGridState-report')}
                      onClick={() =>
                        onLoadCustomGrid({ gridName: 'report', activeTab, gridApiRef })
                      }
                      icon={<BookmarkIcon />}
                    />
                  </div>
                  <div className={classes.cardHeaderSection}>
                    <SearchField gridApiRef={gridApiRef} columns={searchFieldColumns} />
                  </div>
                </div>
                <DataGridPremium
                  sx={premiumGridStyles}
                  columns={columns}
                  apiRef={gridApiRef}
                  rows={rows}
                  getRowId={(row) => row.slot_label}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'row-even' : 'row-odd'
                  }
                  getRowHeight={getGridRowHeight}
                  columnHeaderHeight={45}
                  slots={{
                    toolbar: ReportTableToolbar,
                    loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
                  }}
                  loading={isLoading}
                  onRowClick={(params) =>
                    handleRowClickEvent({
                      params,
                      gridApiRef,
                      dispatchFacilityModals,
                      parentPage: 'Report',
                      reportId,
                      loadLocationData,
                    })
                  }
                  localeText={{
                    // @ts-expect-error customizing types for localeText is not fully supported currently. https://github.com/mui/mui-x/blob/HEAD/packages/x-data-grid/src/constants/localeTextConstants.ts
                    headerFilterOperatorNotContains: 'not contain',
                    headerFilterOperatorIsNotAnyOf: 'is not any of',
                  }}
                  rowGroupingColumnMode="multiple"
                  disableRowSelectionOnClick
                  disableAggregation
                  headerFilters
                  headerFilterHeight={60}
                  onStateChange={handleGridStateChange}
                  initialState={
                    gridViewFromURL
                      ? { ...gridViewFromURL }
                      : {
                          ...REPORT_TABS[activeTab].gridState,
                        }
                  }
                  data-testid="report-table"
                />
              </Card>
            ) : (
              <ReportLocationsGrid
                tabs={tabs}
                activeTab={activeTabRLG}
                changeTab={changeTab}
                reviewLocationsTab={reviewLocationsTab}
                currentTabData={currentTabData}
                reportId={reportId}
                isLoading={fullReportSpinner}
                currentActiveSpinner={currentActiveSpinner}
                reportState={reportState}
                reportNeedsReview={reportNeedsReview}
                getReportData={getReportData}
              />
            )}
          </Box>
        )}
      </CardGrid>
    </Container>
  );
};
