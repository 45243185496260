import { IFacilitySettingsST } from 'codegen/facility_settings';
import { SettingsItem } from '../models/settingsCard.model';
import { BarcodeMatchLogicAlertInfo } from './BarcodeMatchLogicAlertInfo';

export const getIssuesOptions = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: any) => void,
  bcMatchLogics: { label: string; value: string }[],
): SettingsItem[] => [
  {
    type: 'switch',
    label: 'Allow client amend',
    testId: 'c-allow-client-amend',
    description: 'If enabled, the user can amend results collected by Verity.',
    value: facilitySettings.allow_user_amend,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('allow_user_amend', e.target.checked),
  },
  {
    type: 'switch',
    label: 'Additional GSI issue table',
    testId: 'c-additional-gsi-issue-table',
    description: 'If enabled, Additional GSI issues table is used',
    value: facilitySettings.enable_additional_gsi_issue_table,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('enable_additional_gsi_issue_table', e.target.checked),
  },
  {
    type: 'select',
    label: 'Barcode match logic',
    selectOptions: bcMatchLogics,
    testId: 'c-barcode-match-logic',
    description: 'The name of the handler that decides if Verity and WMS barcodes match.',
    alertInfo: <BarcodeMatchLogicAlertInfo />,
    value: facilitySettings.barcode_match_logic_name,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('barcode_match_logic_name', e.target.value),
  },
  {
    type: 'json-textarea',
    label: 'Custom Issue Logic rules',
    testId: 'c-issue-logic-rules',
    description:
      'Define the custom issue logic rules. Please copy/paste your rules definition into the text area.',
    values: facilitySettings.issue_logic_rules,
    action: (jsonRules: any) => handleValuesChange('issue_logic_rules', jsonRules),
  },
];
