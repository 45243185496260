import { IReportST } from 'codegen/report';

import { reportServices } from '../../api/ReportServices';
import { getReport } from './utils/get-report/getReport';
import { asyncGetReportSummariesRecursively } from './utils/asyncGetReportSummariesRecursively';
import { getReportSpecification } from './utils/getReportSpecification';
import { getReportSpecifications } from './utils/getReportSpecifications';
import { getReportSummary } from './utils/getReportSummary';

/**
 * Included Locations = All Locations - Excluded
 * @param report Report data
 * @returns the # of included locations
 */
export const getAllIncludedLocations = (report: IReportST) =>
  report.num_locations - (report.location_data_count.EXCLUDED ?? 0);

export type ReportActionButton = { label: string; disabled: boolean; clickHandler: () => void };

// ###########################################################################
// GET report data
//
// Receives:
//    id - id of the report
//    fromSlot - the name of the slot from where to start the list
//    untilSlot - the name of the last slot to retrieve
// Returns:
//   reportData - dictionary with data for all the locations within the given
//                range
// ###########################################################################
export const getReportData = (
  systemId: string,
  id: string,
  fromSlot: string,
  untilSlot: string,
  signal: AbortSignal,
) =>
  reportServices.getReport(systemId, id, fromSlot, untilSlot, signal).then((r) => ({
    reportData: r.data.item,
  }));

const sendReportEmail = (systemId: string, id: string, signal: AbortSignal) =>
  reportServices.sendReportEmail(systemId, id, signal);

const archiveReports = (systemId: string, ids: string[]) =>
  reportServices.archiveReports(systemId, ids);

const restoreReports = (systemId: string, ids: string[]) =>
  reportServices.restoreReports(systemId, ids);

export const reportStore = {
  getReportSpecifications,
  getReportSpecification,
  getReport,
  getReportSummary,
  asyncGetReportSummariesRecursively,
  getReportData,
  sendReportEmail,
  archiveReports,
  restoreReports,
};
