import { Card, LinearProgress } from '@mui/material';
import {
  DataGridPremium,
  GridInitialState,
  GridSlots,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { GridPremiumToolbarButton } from 'common/Tables/toolbar/GridPremiumToolbarButton';
import { SearchField } from 'udb/inventory/shared/premium-grid/features/search-field/SearchField';
import { premiumGridStyles } from 'udb/inventory/shared/premium-grid/premiumGrid.styles';
import { getGridRowHeight } from 'udb/inventory/shared/premium-grid/utils/getGridRowHeight.util';
import { onLoadCustomGrid } from 'udb/inventory/shared/premium-grid/utils/onLoadCustomGrid.util';
import { onSaveCustomGrid } from 'udb/inventory/shared/premium-grid/utils/onSaveCustomGrid.util';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { useFacilityModalsStore } from 'store/Modals';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { useSearchParams } from 'react-router-dom';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ISSUE_ACTIONS } from 'common/Actions/actionTypes';
import { ILocationDataST } from 'codegen/report';
import LZString from 'lz-string';
import { cleanGridState } from 'udb/inventory/shared/premium-grid/utils/cleanGridState.util';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { usePrevious } from 'hooks/usePrevious';
import { openLocationModal } from 'udb/inventory/shared/premium-grid/utils/openLocationModal.util';
import { getFilteredLocationData } from 'udb/inventory/shared/premium-grid/utils/getFilteredLocationData.util';
import { calculateTabCounts } from 'udb/inventory/shared/premium-grid/utils/calculateTabCounts';
import { searchFieldColumns } from '../../models/searchFieldColumns.model';
import { WAREHOUSE_STATUS_TABS } from '../../models/WarehouseStatusBetaTabs.model';
import { getColumns } from '../../models/WHSColumns.model';
import { WarehouseStatusBetaTabs } from '../tabs/WarehouseStatusBetaTabs';
import { useStyles } from '../../styles/WarehouseStatusBeta.styles';
import { WarehouseStatusBetaToolbar } from '../toolbar/WarehouseStatusBetaToolbar';
import { SnoozeIssuesModal } from '../snooze-issues-modal/SnoozeIssuesModal';
import { serializedInitialGridState } from '../../defaults/serializedInitialGridState.default';

const STARTING_TAB = 1;

export const WarehouseStatusBetaTable = ({
  locationData,
  isLoadingLocations,
  loadLocationData,
  gridState,
}: {
  locationData: ILocationDataST[];
  isLoadingLocations: boolean;
  loadLocationData: () => void;
  gridState?: GridInitialStatePremium;
}) => {
  const { cx, classes } = useStyles();

  const gridApiRef = useGridApiRef();

  const { dispatchFacilityModals } = useFacilityModalsStore();

  const { stateFacilityLevel } = useFacilityLevelStore();

  const { facilitySettings } = stateFacilityLevel;

  const [searchParams, setSearchParams] = useSearchParams();
  const locationFromUrl = searchParams.get('location') || '';

  const [tabCounts, setTabCounts] = useState<{
    [key: string]: number;
  }>({});

  const dataGridStateFromURL = searchParams.get('dataGridState');
  const [gridViewFromURL] = useState<GridInitialState>(
    dataGridStateFromURL
      ? JSON.parse(LZString.decompressFromEncodedURIComponent(dataGridStateFromURL))
      : undefined,
  );

  const [activeTab, setActiveTab] = useState<number>(
    Number(searchParams.get('activeTab') ?? STARTING_TAB),
  );
  const [checkboxSelection, setCheckboxSelection] = useState(false);
  const [isSnoozeModalOpen, setIsSnoozeModalOpen] = useState(false);
  const [snoozeAction, setSnoozeAction] = useState(ISSUE_ACTIONS.SNOOZE);
  const [serializedGridState, setSerializedGridState] = useState('');
  const columns = useMemo(() => getColumns(facilitySettings), [facilitySettings]);

  const rowCount = locationData.length;

  const handleTabChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      setActiveTab(newValue);
      const { gridState } = WAREHOUSE_STATUS_TABS[newValue];
      gridApiRef.current.restoreState({ ...gridState });

      searchParams.set('activeTab', newValue.toString());
      setSearchParams(searchParams);
    },
    [gridApiRef, searchParams, setSearchParams],
  );

  useEffect(() => {
    if (locationFromUrl && !isLoadingLocations) {
      openLocationModal(
        locationFromUrl,
        gridApiRef,
        dispatchFacilityModals,
        'WarehouseStatus',
        loadLocationData,
        locationData,
      );
    }
  }, [
    dispatchFacilityModals,
    gridApiRef,
    isLoadingLocations,
    loadLocationData,
    locationFromUrl,
    locationData,
  ]);

  // NOTE: this is used later in a useEffect to allow additional changes in the filters, columns, etc.
  // while a gridState prop is passed (e.g. when clicking one of the header's chart)
  // eno: 2024-09-02
  const previousGridState = usePrevious(gridState) as GridInitialStatePremium | undefined;

  useEffect(() => {
    // NOTE: the latter part of the if statement avoids to reapply the grid state
    // passed as a prop when the table filtering/columns/etc. has been altered manually
    // eno: 2024-09-02
    if (gridState && gridState !== previousGridState) {
      handleTabChange(null as unknown as ChangeEvent<HTMLInputElement>, 0);
      const { gridState: fullTableState } = WAREHOUSE_STATUS_TABS[0];
      gridApiRef.current.restoreState({ ...fullTableState, ...gridState });
    }
  }, [gridApiRef, gridState, handleTabChange, previousGridState]);

  useEffect(() => {
    setTabCounts(calculateTabCounts({ locationDataList: locationData }));
  }, [locationData, rowCount]);

  const tableToolbar = () => (
    <WarehouseStatusBetaToolbar
      checkboxSelection={checkboxSelection}
      setCheckboxSelection={setCheckboxSelection}
      onSnooze={() => {
        setSnoozeAction(ISSUE_ACTIONS.SNOOZE);
        setIsSnoozeModalOpen(true);
      }}
      onUnSnooze={() => {
        setSnoozeAction(ISSUE_ACTIONS.UNSNOOZE);
        setIsSnoozeModalOpen(true);
      }}
    />
  );

  const handleGridStateChange = () => {
    const currentGridState = cleanGridState(gridApiRef.current.exportState());
    const currentSerializedGridState = JSON.stringify(currentGridState);

    if (serializedGridState !== currentSerializedGridState) {
      setSerializedGridState(currentSerializedGridState);
    }
  };

  useEffect(() => {
    if (serializedGridState === serializedInitialGridState || serializedGridState === '') {
      return;
    }
    const urlState = searchParams.get('dataGridState');
    const urlStateDecompressed = urlState
      ? LZString.decompressFromEncodedURIComponent(urlState)
      : null;

    if (serializedGridState !== urlStateDecompressed) {
      const compressedState = LZString.compressToEncodedURIComponent(serializedGridState);
      searchParams.set('dataGridState', compressedState);
      setSearchParams(searchParams);
    }
  }, [searchParams, serializedGridState, setSearchParams]);

  const selectedRows = () =>
    Array.from(gridApiRef.current.getSelectedRows(), ([_id, value]) => value) as ILocationDataST[];

  return (
    <>
      <Card
        sx={{
          height: 'clamp(500px, 700px, calc(100vh - 200px))',
        }}
      >
        <div className={classes.cardHeader}>
          <WarehouseStatusBetaTabs
            tabs={WAREHOUSE_STATUS_TABS}
            activeTab={activeTab}
            handleTabChange={handleTabChange}
            tabCounts={tabCounts}
          />

          <div className={cx(classes.cardHeaderSection, classes.cardHeaderActions)}>
            <GridPremiumToolbarButton
              name="SAVE VIEW"
              tooltip="Save View"
              onClick={() =>
                onSaveCustomGrid({ gridName: 'warehouse-status', activeTab, gridApiRef })
              }
              icon={<BookmarkAddIcon />}
            />

            <GridPremiumToolbarButton
              name="LOAD VIEW"
              tooltip="Load View"
              disabled={
                !(
                  localStorage.getItem('savedGridState-warehouse-status') ||
                  localStorage.getItem('savedGridState')
                )
              }
              onClick={() =>
                onLoadCustomGrid({ gridName: 'warehouse-status', activeTab, gridApiRef })
              }
              icon={<BookmarkIcon />}
            />
          </div>

          <div className={classes.cardHeaderSection}>
            <SearchField gridApiRef={gridApiRef} columns={searchFieldColumns} />
          </div>
        </div>
        <DataGridPremium
          sx={premiumGridStyles}
          columns={columns}
          apiRef={gridApiRef}
          rows={getFilteredLocationData(
            locationData,
            WAREHOUSE_STATUS_TABS[activeTab].issueTypeFilters,
            WAREHOUSE_STATUS_TABS[activeTab].issueStateFilters,
            WAREHOUSE_STATUS_TABS[activeTab].slotSettingFilter,
          )}
          getRowId={(row) => row.slot_label}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'row-even' : 'row-odd'
          }
          getRowHeight={getGridRowHeight}
          columnHeaderHeight={45}
          slots={{
            toolbar: tableToolbar,
            loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
          }}
          loading={isLoadingLocations}
          onRowClick={(params) => {
            openLocationModal(
              params.row.slot_label,
              gridApiRef,
              dispatchFacilityModals,
              'WarehouseStatus',
              loadLocationData,
              locationData,
            );
            searchParams.set('location', params.row.slot_label);
            setSearchParams(searchParams);
          }}
          localeText={{
            // @ts-expect-error customising types for localeText is not fully supported currently. https://github.com/mui/mui-x/blob/HEAD/packages/x-data-grid/src/constants/localeTextConstants.ts
            headerFilterOperatorNotContains: 'not contain',
            headerFilterOperatorIsNotAnyOf: 'is not any of',
          }}
          checkboxSelection={checkboxSelection}
          rowGroupingColumnMode="multiple"
          disableRowSelectionOnClick
          disableAggregation
          headerFilters
          headerFilterHeight={60}
          onStateChange={handleGridStateChange}
          initialState={
            gridViewFromURL
              ? { ...gridViewFromURL }
              : {
                  ...WAREHOUSE_STATUS_TABS[activeTab].gridState,
                }
          }
        />
      </Card>
      {isSnoozeModalOpen && (
        <SnoozeIssuesModal
          locations={selectedRows()}
          action={snoozeAction}
          onClose={() => setIsSnoozeModalOpen(false)}
          refreshData={loadLocationData}
        />
      )}
    </>
  );
};
