import { GridValueGetter } from '@mui/x-data-grid-premium';
import { ILocationDataST } from 'codegen/warehouse_status';
import moment from 'moment';

export const emptyCellString = '-';

export const collectionDelayValueGetter: GridValueGetter<ILocationDataST, number | null> = (
  value,
  row,
) => {
  const verityDate = typeof row.verity_status !== 'string' && row.verity_status?.collected_at;
  const wmsDate = typeof row.wms_status !== 'string' && row.wms_status?.changed_at;

  if (!verityDate || !wmsDate) {
    return null;
  }

  return moment(verityDate).diff(moment(wmsDate), 'hour');
};
