import { GridValueGetter } from '@mui/x-data-grid';
import { ILocationDataST } from 'codegen/warehouse_status';
import { getMatchingBarcodes, getExpectedBarcodes } from '../barcodeFunctions';
import { emptyCellString } from './collectionDelayValueGetter';

export const matchingBarcodesPercentageValueGetter: GridValueGetter<ILocationDataST> = (
  value,
  row,
): number | '-' => {
  const matching = getMatchingBarcodes(row);
  const expected = getExpectedBarcodes(row);

  return matching && expected ? matching.length / expected.length : emptyCellString;
};
