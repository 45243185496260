import { GridValueGetter } from '@mui/x-data-grid';
import { ILocationDataST, IVeritySlotStatusST } from 'codegen/warehouse_status';
import { DISPLAY_VERITY_STATES } from 'common/slotStates';
import { emptyCellString } from './collectionDelayValueGetter';

export const contentFoundValueGetter: GridValueGetter<ILocationDataST> = (value, row): string => {
  const verityStatus = row.verity_status as IVeritySlotStatusST;

  if (!verityStatus || typeof verityStatus === 'string') {
    return verityStatus;
  }

  const { barcodes: verityBarcodes, user_overrides: userOverrides } = verityStatus;

  let content: string = emptyCellString;

  const showOverrides = userOverrides?.length;

  if (showOverrides && userOverrides?.[userOverrides!.length - 1].state !== 'BARCODE') {
    return DISPLAY_VERITY_STATES[userOverrides?.[userOverrides!.length - 1].state];
  }
  const barcodes = showOverrides
    ? userOverrides?.[userOverrides!.length - 1].barcodes
    : verityBarcodes;

  if (barcodes.length === 1) {
    const barcode = barcodes[0];
    content = `${barcode} ${showOverrides ? ' *' : ''}`;
  } else if (barcodes.length > 1) {
    const list = barcodes.map((barcode) => `${barcode} ${showOverrides ? ' *' : ''}`);
    content = list.join(', ');
  } else {
    content = DISPLAY_VERITY_STATES[verityStatus.state];
  }

  return content;
};
