import { Button, TextField } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { getInvalidEmailErrorMessage } from 'udb/sign-in/features/SignInForm/utils/getInvalidEmailErrorMessage.utils';
import { isValidEmailFormat } from 'utils/isValidEmailFormat';
import { REDIRECT_URL } from '../../../../pages/Auth/login-callback/loginCallback.defaults';
import { SignInFormProps } from './model/signInForm.model';
import { styles } from './styles/signInForm.styles';
import { InputPassword } from '../InputPassword/InputPassword';
import { AUTH_PAGES_URLS } from '../../../../common/pages';

export const SignInForm = (props: SignInFormProps) => {
  const {
    username,
    password,
    additionalInfo,
    signInWithDifferentAccount,
    showForgotPasswordLink,
    handleSubmit,
    handleChange,
    isDisabled = false,
  } = props;
  const { classes } = styles();

  const allInputAreValid = username && password && isValidEmailFormat(username);

  const showSsoButton = import.meta.env.VITE_APP_ENABLE_SSO === 'true';
  const redirectURL = encodeURIComponent(REDIRECT_URL);
  const ssoUrl = import.meta.env.VITE_APP_SSO_SIGN_IN_URL?.replace(
    'REDIRECT_URI_PLACEHOLDER',
    redirectURL,
  );

  return (
    <section className={classes.wrapper}>
      {showSsoButton && (
        <>
          <header>
            <Button
              variant="outlined"
              startIcon={<MicrosoftIcon />}
              fullWidth
              color="secondary"
              href={ssoUrl}
              disabled={isDisabled}
            >
              Sign in with microsoft
            </Button>
          </header>

          <p aria-hidden className={classes.divider}>
            or
          </p>
        </>
      )}

      <form
        data-testid="c-sign-in-form"
        name="signin"
        onSubmit={handleSubmit}
        className={classes.form}
      >
        <TextField
          variant="outlined"
          margin="none"
          fullWidth
          id="username"
          label="Email"
          name="username"
          autoComplete="email"
          error={Boolean(username && !isValidEmailFormat(username))}
          helperText={username && getInvalidEmailErrorMessage(username)}
          autoFocus
          inputProps={{ 'data-testid': 'username' }}
          onChange={handleChange}
          disabled={isDisabled}
        />

        <InputPassword
          id="password"
          variant="outlined"
          name="password"
          label="Password"
          autoComplete="current-password"
          validate={false}
          inputProps={{ 'data-testid': 'password' }}
          onChange={handleChange}
          disabled={isDisabled}
        />

        {additionalInfo && <div>{additionalInfo}</div>}

        {signInWithDifferentAccount && <div>{signInWithDifferentAccount}</div>}

        <div className={classes.footer}>
          {showForgotPasswordLink && (
            <div className={classes.footerSection}>
              <Link
                className={classes.inlineLink}
                component={RouterLink}
                data-testid="forgotPasswordLink"
                to={AUTH_PAGES_URLS.FORGOT_PASSWORD}
                sx={{
                  textAlign: 'center',
                }}
              >
                Forgot your password?
              </Link>
            </div>
          )}

          <Button
            data-testid="signInButton"
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            disabled={!allInputAreValid || isDisabled}
            endIcon={<KeyboardArrowRightIcon />}
          >
            Sign In
          </Button>
        </div>
      </form>
    </section>
  );
};
