import React from 'react';

export const WMSRawValue = ({
  name,
  value,
  classes,
}: {
  name: string;
  value: unknown;
  classes: { key: string; value: string };
}) => (
  <>
    <div className={classes.key}>{name}</div>
    <div className={classes.value}>
      {typeof value === 'string' || typeof value === 'number'
        ? value.toString()
        : JSON.stringify(value)}
    </div>
  </>
);
