import { createContext, useContext, useReducer } from 'react';
import { initialFacilityModalsState } from './facilityModalInitialState';
import { modalsReducer } from './facilityModalsReducer';
import { IModalsStoreContext } from './IFacilityModalsStore';

const initialContext: IModalsStoreContext = {
  facilityModalsState: initialFacilityModalsState,
  dispatchFacilityModals: () => {},
};

const Store = createContext(initialContext);

export const FacilityModalsStoreProvider = ({ children }: any) => {
  const [facilityModalsState, dispatchFacilityModals] = useReducer(
    modalsReducer,
    initialFacilityModalsState,
  );

  return (
    <Store.Provider value={{ facilityModalsState, dispatchFacilityModals }}>
      {children}
    </Store.Provider>
  );
};

export const useFacilityModalsStore = () => useContext(Store);
