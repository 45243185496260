import { ILocationDataST } from 'codegen/report';
import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';
import { getLatestOverwriteFromSlotStatus } from 'common/functions/slot/utils/getLatestOverwriteFromSlotStatus';
import { ILocationReportData } from 'udb/inventory/features/warehouse-status/warehouseStatusFunctions';

export const getFullReportAndAmended = (locationsData: Record<string, ILocationDataST>) => {
  const fullReport: ILocationReportData[] = [];
  const amended: ILocationReportData[] = [];

  Object.entries(locationsData).forEach(([location, locationData]) => {
    let rowData = {} as ILocationReportData;
    rowData = getRowForFullReportTable(
      location,
      locationData,
      locationData.issues,
      locationData.state,
    );

    fullReport.push(rowData);

    if (getLatestOverwriteFromSlotStatus(locationData.verity_status, 'client')) {
      amended.push(rowData);
    }
  });

  return { fullReport, amended };
};
