import { Configuration as TasksConfig, IGetTaskResponseSTTypeEnum, TasksApi } from 'codegen/tasks';

/**
 * Get commissioning tasks api service
 * @returns api service
 */
const getTaskApiService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new TasksConfig({ apiKey: idToken });
  const apiService = new TasksApi(config);
  return apiService;
};

/**
 * getRawCommissioningTasks get commissioning tasks
 * @param {string} systemId
 * @param {string} flightDomainId
 * @param {any} filterDates
 * @param {string} state
 * @param {string} pageToken
 * @param {number} limit
 * @returns void
 */
export const getRawCommissioningTasks = (
  systemId: string,
  flightDomainId: string,
  filterDates: any,
  state?: string,
  pageToken?: string,
  limit?: number,
) =>
  getTaskApiService().getRawCommissioningTasks(
    systemId,
    flightDomainId,
    IGetTaskResponseSTTypeEnum.Raw,
    filterDates?.from,
    filterDates?.until,
    state,
    pageToken,
    limit,
  );

/**
 * abortCommissioningTasks aborts the task with given taskId
 * @param {string} systemId
 * @param {string} taskId
 * @returns void
 */
export const abortCommissioningTasks = (systemId: string, taskId: string) =>
  getTaskApiService().systemIdStrTaskTaskIdAbortPut(systemId, taskId);
