import { GridValueGetter } from '@mui/x-data-grid';
import { ILocationDataST } from 'codegen/warehouse_status';

export const firstFoundOnValueGetter: GridValueGetter<ILocationDataST, Date | null> = (
  value,
  row,
) => {
  const dateTime = row.issues && row.issues[0]?.first_found_on;
  if (!dateTime) {
    return null;
  }

  return new Date(dateTime);
};
