import { IFacilitySettingsST } from 'codegen/facility_settings';
import { SettingsItem } from '../models/settingsCard.model';

export const getAuthenticationOptions = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: any) => void,
): SettingsItem[] => [
  {
    type: 'switch',
    label: 'Enable Multi-factor Authentication',
    testId: 'c-enable-multi-factor-authenticate',
    description: 'If not enabled, MFA is disabled for all the users of the facility.',
    value: facilitySettings.enable_mfa,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('enable_mfa', e.target.checked),
  },
];
