import moment from 'moment-timezone';

import { DATETIME_FORMAT_FOOTER } from 'common/datetimeFormats';

import { BaseCard } from 'components/BaseCard/BaseCard';
import {
  BaseCardSimpleItem,
  IBaseCardSimpleItemProps,
} from 'components/BaseCard/BaseCardSimpleItem';
import { LocalStore } from 'common/functions/storageFunctions';
import { getLockReason } from 'pages/GroundControl/pages/FlightDomainOverview/FlightDomainOverviewFunctions';
import { IFlightDomainData } from '../../../store/GroundControl/IGroundControlStore';

/**
 * Props for FacilityDetailsCard
 */
interface IFlightDomainsCardProp {
  /**
   * Flag to indicate if the facility is active
   */
  facilityActive: boolean;
  /**
   * Facility Flight domains
   */
  flightDomains: Array<IFlightDomainData>;
}

/**
 * FlightDomains card
 * @param props: IFlightDomainsCardProp
 * @returns Flight Domains card component
 */
export const FlightDomainsCard = ({ facilityActive, flightDomains }: IFlightDomainsCardProp) => {
  const timezone = LocalStore.getTimezone();

  const noDataAvailable = !flightDomains;

  const sections = flightDomains.map((flightDomain: IFlightDomainData) => {
    let items: IBaseCardSimpleItemProps['sectionItems'] = [];

    items = [
      {
        id: 'fd-card-flight-domain-status',
        label: 'Status',
        value:
          (flightDomain.flight_domain_status &&
            (flightDomain.flight_domain_status?.locked ? 'Locked' : 'Unlocked')) ||
          '-',
        testId: 'c-flight-domain-status',
      },
      {
        id: 'fd-card-flight-lock-reason',
        label: 'Lock reason',
        value:
          (flightDomain.flight_domain_status?.lock_reason &&
            getLockReason(flightDomain.flight_domain_status.lock_reason)) ||
          '-',
        testId: 'c-flight-domain-lock-reason',
      },
      {
        id: 'fd-card-flight-lock-unlock-date',
        label: 'Last Lock/Unlock date',
        value:
          (flightDomain.flight_domain_status &&
            moment(flightDomain.flight_domain_status?.last_locked_unlocked_time)
              .tz(timezone)
              .format(DATETIME_FORMAT_FOOTER)) ||
          '-',
        testId: 'c-flight-domain-lock-unlock-date',
      },
      {
        id: 'fd-card-fleet-number',
        label: 'Number of drones',
        value:
          (flightDomain.fleet_status_summary &&
            `${
              flightDomain.fleet_status_summary.num_drones_flying +
              flightDomain.fleet_status_summary.num_drones_landed_in_error +
              flightDomain.fleet_status_summary.num_drones_on_charger
            } drones`) ||
          '-',
        testId: 'c-flight-domain-fleet-number',
      },
    ];

    return {
      sectionTitle: flightDomain.flight_domain_name,
      testId: `c-flight-domain-${flightDomain.flight_domain_name}`,
      isWidget: false,
      sectionItems: items,
    };
  });

  const flightDomainInfoCardData = {
    title: 'Flight Domains',
    subtitle: facilityActive && flightDomains ? 'Facility flight domains' : '',
    sections: facilityActive && sections,
  };

  return (
    <BaseCard
      cardFor="flight domains"
      dataTestId="c-flight-domains-info-card"
      showHeader={true}
      showContent={true}
      showActionButtons={false}
      showHeaderDivider={true}
      title={flightDomainInfoCardData.title}
      subtitle={flightDomainInfoCardData.subtitle}
      noData={noDataAvailable}
    >
      {flightDomainInfoCardData &&
        flightDomainInfoCardData.sections &&
        flightDomainInfoCardData.sections.map((section, i) => (
          <BaseCardSimpleItem
            key={section.sectionTitle}
            testId={`c-flight-domain-list-item-${i}`}
            title={section.sectionTitle}
            isWidget={section.isWidget}
            sectionItems={section.sectionItems}
          />
        ))}
    </BaseCard>
  );
};
