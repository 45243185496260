import { createContext, ReactNode, useCallback, useContext, useReducer } from 'react';

import { getLogPrefixForType } from 'common/functions/logFunctions';
import { LocalStore } from 'common/functions/storageFunctions';
import { IFleetVersionResponseST } from 'codegen/flight_domain';
import { useFacilityLevelStore } from '../FacilityLevelStore/facilityLevelStore';
import { groundControlServices } from '../../services/GroundControlServices';
import { groundControlReducer } from './groundControlLevelReducer';

import { GroundControlActionNames } from './groundControlLevelActions';
import { IFlightDomainData, IGroundControlLevelContext } from './IGroundControlStore';
import {
  initialFleetOverview,
  initialFlightDomain,
  initialGroundControlState,
} from './groundControlInitialState';
import { FleetOverviewST } from '../../pages/GroundControl/pages/FleetOverview/API';

import { IRequestController, useRequestController } from '../../hooks';
import { useUserLevelStore } from '../UserLevelStore/userLevelStore';
import { FlightDomainScheduleST } from '../../udb/ground-control/schedule/features/droneFlightHours/model/operatingHours.model';

export const initialContext: IGroundControlLevelContext = {
  stateGroundControl: initialGroundControlState,
  dispatchGroundControlLevel: () => {},
  isDataReady: () => false,
  asyncGetFleetOverview: (() => {}) as unknown as () => Promise<any>,
  asyncGetFleetVersion: () => Promise.resolve('no impl' as unknown as IFleetVersionResponseST),
  asyncGetFlightDomainInfo: () => {},
  getFlightDomainData: (() => {}) as unknown as (
    requestController: IRequestController,
    flightDomainId: string,
  ) => Promise<void>,
  refreshFlightDomainData: (() => {}) as unknown as (
    requestController: IRequestController,
    flightDomainId: string,
  ) => void,
  populateFlightDomainSchedule: (() => {}) as unknown as () => Promise<any>,
  saveFlightDomainSchedule: (() => {}) as unknown as () => Promise<any>,
};

const GroundControlLevelStore = createContext(initialContext);

export const useGroundControlStore = () => useContext(GroundControlLevelStore);

const logPrefix = getLogPrefixForType('STORE', 'GroundControlStoreProvider');

export const GroundControlStoreProvider = ({ children }: { children: ReactNode }) => {
  const [stateGroundControl, dispatchGroundControlLevel] = useReducer(
    groundControlReducer,
    initialGroundControlState,
  );

  const { stateUserLevel } = useUserLevelStore();
  const { stateFacilityLevel, updateFlightDomainList } = useFacilityLevelStore();

  const { requestController } = useRequestController(logPrefix);

  const asyncGetFlightDomainInfo = useCallback(
    (requestController: IRequestController, systemId: string, flightDomainId: string) => {
      console.debug(logPrefix, `asyncGetFlightDomainInfo ${requestController.componentName || ''}`);
      const reservation = requestController.reserveSlotForRequest();

      let flightDomainData: IFlightDomainData = initialFlightDomain;

      return requestController.doRequest({
        request: groundControlServices.getFlightDomainInfo,
        requestParams: [systemId, flightDomainId, reservation.signal],
        messageErrorFallback: 'The flight domain status could not be fetched.',
        callbackSuccess: (response: { data: Omit<IFlightDomainData, 'fleet_status_summary'> }) => {
          flightDomainData = { ...flightDomainData, ...response.data };

          requestController.doRequest({
            request: groundControlServices.getFleetStatusSummary,
            requestParams: [systemId, flightDomainId, reservation.signal],
            messageErrorFallback: 'The flight domain fleet status summary could not be fetched.',
            callbackSuccess: (response: {
              data: Omit<IFlightDomainData, 'flight_domain_status'>;
            }) => {
              flightDomainData = { ...flightDomainData, ...response.data };

              // set flight domain data
              dispatchGroundControlLevel({
                type: GroundControlActionNames.SET_FLIGHT_DOMAIN,
                payload: flightDomainData,
              });
              // update flight domains list in facilityLevelStore
              updateFlightDomainList(flightDomainData);
            },
            callbackError: () => {
              dispatchGroundControlLevel({
                type: GroundControlActionNames.SET_FLIGHT_DOMAIN,
                payload: flightDomainData,
              });
            },
          });
        },
        callbackError: () => {
          dispatchGroundControlLevel({
            type: GroundControlActionNames.SET_FLIGHT_DOMAIN,
            payload: flightDomainData,
          });
        },
      });
    },
    [updateFlightDomainList],
  );

  /**
   * Is ground control data ready
   *
   * Note: the ground control store also checks that the web-socket has been authorized.
   * This happens here because, in order to speed-up the login process, the check about web-socket
   * is not done at user level store (where the web socket is actually initialized). See UD-3267.
   */
  const isDataReady = useCallback(() => {
    const isDataReady =
      stateFacilityLevel.flightDomainsLoaded &&
      stateGroundControl.flightDomainLoaded &&
      stateUserLevel.isWebSocketAuthorized;
    console.debug(logPrefix, `is data ready: ${isDataReady}`);
    return isDataReady;
  }, [
    stateFacilityLevel.flightDomainsLoaded,
    stateGroundControl.flightDomainLoaded,
    stateUserLevel.isWebSocketAuthorized,
  ]);

  const asyncGetFleetOverview = useCallback(
    async (requestController: IRequestController, flightDomainId: string) => {
      const reservation = requestController.reserveSlotForRequest();
      const lp = getLogPrefixForType(
        'FUNCTION',
        'asyncGetFleetOverview',
        logPrefix + requestController.componentName,
      );
      console.debug(lp, `Get fleet overview ${requestController.componentName || ''}`);
      return requestController.doRequest({
        request: groundControlServices.getFleetOverview,
        requestParams: [stateFacilityLevel.currentSystemId, flightDomainId, reservation.signal],
        callbackBeforeSend: () => {
          dispatchGroundControlLevel({
            type: GroundControlActionNames.SET_IS_LOADING_FLEET_DATA,
            payload: true,
          });
        },
        callbackSuccess: (res: { data: FleetOverviewST }) => {
          dispatchGroundControlLevel({
            type: GroundControlActionNames.SET_FLEET_OVERVIEW,
            payload: res.data,
          });
        },
        callbackError: () => {
          dispatchGroundControlLevel({
            type: GroundControlActionNames.SET_IS_LOADING_FLEET_DATA,
            payload: false,
          });
        },
      });
    },
    [stateFacilityLevel.currentSystemId],
  );

  const asyncGetFleetVersion = useCallback(
    (
      requestController: IRequestController,
      flightDomainId: string,
    ): Promise<IFleetVersionResponseST> =>
      new Promise((resolve) => {
        const reservation = requestController.reserveSlotForRequest();

        console.debug(logPrefix, `asyncGetFleetVersion ${requestController.componentName || ''}`);

        requestController.doRequest({
          request: groundControlServices.getFleetVersion,
          requestParams: [stateFacilityLevel.currentSystemId, flightDomainId, reservation.signal],
          callbackSuccess: (res: { data: IFleetVersionResponseST }) => {
            dispatchGroundControlLevel({
              type: GroundControlActionNames.SET_FLEET_VERSION,
              payload: res.data,
            });

            resolve(res.data as unknown as IFleetVersionResponseST);
          },
        });
      }),
    [stateFacilityLevel.currentSystemId],
  );

  const getFlightDomainData = useCallback(
    async (requestController: IRequestController, systemId: string, flightDomainId: string) => {
      asyncGetFleetOverview(requestController, flightDomainId);
      asyncGetFlightDomainInfo(requestController, systemId, flightDomainId);
    },
    [asyncGetFleetOverview, asyncGetFlightDomainInfo],
  );

  const refreshFlightDomainData = useCallback(
    (requestController: IRequestController, systemId: string, flightDomainId: string) => {
      dispatchGroundControlLevel({
        type: GroundControlActionNames.SET_FLEET_OVERVIEW,
        payload: initialFleetOverview,
      });

      getFlightDomainData(requestController, systemId, flightDomainId);
    },
    [getFlightDomainData],
  );

  const populateFlightDomainSchedule = useCallback(
    async (flightDomainId: string) =>
      requestController.doRequest({
        request: groundControlServices.getFlightDomainSettings,
        requestParams: [stateFacilityLevel.currentSystemId, flightDomainId],
        callbackSuccess: (response: { data: FlightDomainScheduleST }) => {
          dispatchGroundControlLevel({
            type: GroundControlActionNames.SET_FLIGHT_DOMAIN_SETTINGS,
            payload: response.data,
          });
        },
      }),
    [requestController, stateFacilityLevel.currentSystemId],
  );

  const saveFlightDomainSchedule = useCallback(
    async (schedule: FlightDomainScheduleST) => {
      const fdId = LocalStore.getFlightDomainId();
      if (!fdId) {
        console.error(logPrefix, 'saveFlightDomainSchedule - No flight domain selected');
        return Promise.reject();
      }

      return requestController.doRequest({
        request: groundControlServices.setFlightDomainSettings,
        requestParams: [stateFacilityLevel.currentSystemId, fdId, schedule],
        callbackSuccess: () => {
          populateFlightDomainSchedule(fdId);
          asyncGetFlightDomainInfo(
            requestController,
            stateFacilityLevel.currentSystemId as string,
            fdId,
          );
        },
      });
    },
    [
      asyncGetFlightDomainInfo,
      populateFlightDomainSchedule,
      requestController,
      stateFacilityLevel.currentSystemId,
    ],
  );

  return (
    <GroundControlLevelStore.Provider
      value={{
        stateGroundControl,
        dispatchGroundControlLevel,
        isDataReady,
        asyncGetFleetOverview,
        asyncGetFleetVersion,
        getFlightDomainData,
        populateFlightDomainSchedule,
        saveFlightDomainSchedule,
        refreshFlightDomainData,
        asyncGetFlightDomainInfo,
      }}
    >
      {children}
    </GroundControlLevelStore.Provider>
  );
};
