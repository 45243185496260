import { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import isEmpty from 'lodash/isEmpty';

// components
import { Box } from 'components/common/Box';
import { CustomSelectWithImages } from 'components/common/CustomFormComponents/CustomSelectWithImages';
import { ExpansibleDragAndDropFile } from 'components/common/ExpansibleDragAndDropFile/ExpansibleDragAndDropFile';
import { BaseCard, IBaseCardActionButton } from 'components/BaseCard/BaseCard';

// interfaces
import { IOption } from '../../../interfaces/inputs';
// parent
import { FacilityDetailsFields, DirtyFacilityDetailsFields } from './FacilityDetails';

interface IFacilityMapCardProps {
  facilityActive: boolean;
  facilityData: any;
  fileDetails: File;
  values: FacilityDetailsFields;
  dirtyFields: DirtyFacilityDetailsFields;
  logos: IOption[];
  uploadInfo: JSX.Element;
  isUploadDisabled: boolean;
  isLoading: boolean;
  handleChange: (
    s: keyof FacilityDetailsFields,
  ) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
  ) => void;
  /**
   * Handler which is invoked when the upload button is clicked.
   */
  handleUpload: () => void;
  setFileDetails: (fileDetails: File) => void;
}

const logoURLHelperText = (
  facilityActive: boolean,
  dirtyFieldsLogoURL: boolean,
  valuesLogoURL: string,
) => {
  if (facilityActive) return 'This field is not mandatory';
  if (dirtyFieldsLogoURL && isEmpty(valuesLogoURL)) return 'You need to set the logo URL';
  return '';
};

/**
 * FacilityMapCard
 * @param params: IFacilityMapCardProps
 * @returns facility map card component
 */
export const FacilityMapCard = ({
  facilityActive,
  facilityData,
  fileDetails,
  values,
  dirtyFields,
  logos,
  uploadInfo,
  isUploadDisabled,
  isLoading,
  handleChange,
  handleUpload,
  setFileDetails,
}: IFacilityMapCardProps) => {
  // Facility map, logo and store id card data
  const actionButtons: IBaseCardActionButton[] = [
    {
      testId: 'c-upload-facility-data-button',
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
      disabled: isUploadDisabled,
      label: 'Upload facility data',
      clickHandler: () => handleUpload(),
    },
  ];
  const facilityMapCardData = {
    title: 'Facility map upload',
    subtitle: facilityActive
      ? 'Upload map, logo and set store ID'
      : 'No facility map was uploaded yet',
    actionButtons,
  };

  return (
    <BaseCard
      cardFor="facility map data"
      showHeader={true}
      showContent={true}
      showActionButtons={true}
      showHeaderDivider={true}
      title={facilityMapCardData.title}
      subtitle={facilityMapCardData.subtitle}
      actionButtons={facilityMapCardData.actionButtons}
      isLoading={isEmpty(facilityData) && isLoading}
    >
      {uploadInfo}
      <Box key="1" px={2} display="flex" flexDirection="column">
        {!isEmpty(logos) && (
          <CustomSelectWithImages
            testId="c-logo-select"
            variant="outlined"
            margin="normal"
            id="logo_url"
            label="Logo"
            name="logo_url"
            value={facilityData?.logo_url || values.logo_url}
            valueOptions={logos}
            disabled={isUploadDisabled}
            error={!facilityActive && dirtyFields.logo_url && isEmpty(values.logo_url)}
            helperText={logoURLHelperText(facilityActive, dirtyFields.logo_url, values.logo_url)}
            onChange={handleChange('logo_url')}
          />
        )}
        <TextField
          data-testid="c-store-id-input"
          error={!facilityActive && dirtyFields.store_id && isEmpty(values.store_id)}
          helperText={
            facilityActive
              ? 'This field is not mandatory'
              : dirtyFields.store_id &&
                isEmpty(values.store_id) &&
                'You need to set the Store ID of the facility'
          }
          value={values.store_id}
          variant="outlined"
          margin="normal"
          fullWidth
          id="store_id"
          label="Store ID"
          name="store_id"
          disabled={isUploadDisabled}
          onChange={handleChange('store_id')}
        />
        <Box my={2}>
          <ExpansibleDragAndDropFile
            title="Upload facility map"
            testId="c-map-upload"
            dropzoneText="Drag and drop map file here..."
            fileDetails={fileDetails}
            isUploadDisabled={isUploadDisabled}
            onDrop={(e) => setFileDetails(e[0])}
          />
        </Box>
      </Box>
    </BaseCard>
  );
};
