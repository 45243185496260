import LockIcon from '@mui/icons-material/Lock';
import { Theme, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import moment from 'moment-timezone';
import { NavigateFunction } from 'react-router-dom';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import {
  DATETIME_FORMAT_FOOTER,
  FLIGHT_DOMAIN_DRONE_SHIFT_FORMAT,
  RELATIVE_DATETIME_FORMAT,
} from 'common/datetimeFormats';
import { LOCK_REASON, renderLockReason } from 'common/groundControlHelpers';
import { GROUND_CONTROL_PAGES_URLS } from 'common/pages';
import { IBaseCardActionButton } from 'components/BaseCard/BaseCard';
import { DroneIcon } from 'components/common/FacilityMenu/Icons/DroneIcon';
import { INoFlyZonesResponseST } from 'codegen/no_fly_zone';
import { LocalStore } from 'common/functions/storageFunctions';
import {
  IDroneOperatingHoursST,
  IFlightDomainStatusST,
  IFlightDomainStatusSTLockReasonEnum,
} from 'codegen/nav_simulation';
import { DroneOnChargerIcon } from '../../Icons/DroneOnChargerIcon';
import { IFlightDomainData } from '../../../../store/GroundControl/IGroundControlStore';
import { formatDateTimeByTimeZoneAndCalendarSpec } from './functions/FormatDateTimeByTimeZoneAndCalendarSpec';

/**
 *
 * @param numberOfDrones number of drones
 * @param skipDot skip the dot at the end of the message (e.g.: if the message is followed by :)
 * @returns number of drones message
 */
export const landedDronesMessage = (numberOfDrones: number, skipDot: boolean = false) => {
  const drones = numberOfDrones === 1 ? 'drone' : 'drones';
  const verb = numberOfDrones === 1 ? 'has' : 'have';
  const verb2 = numberOfDrones === 1 ? 'is' : 'are';
  return `${numberOfDrones} ${drones} ${verb} landed in place or ${verb2} in an error state${
    skipDot ? '' : '.'
  }`;
};

// utils
const renderFleetSummaryInfo = (
  nDronesLanded: number,
  isExecutingEmergencyLanding: boolean,
  isExecutingReturnHome: boolean,
  isTriggeredBySystem: boolean,
  dronesFlying: boolean,
) => {
  if (isExecutingEmergencyLanding) {
    return 'Safety landing requested: flying drones are attempting to landing in place.';
  }

  if (isExecutingReturnHome && isTriggeredBySystem) {
    return 'Drones are returning to their chargers due an automatic system lock.';
  }

  if (nDronesLanded > 0) {
    return `${landedDronesMessage(nDronesLanded, true)}.`;
  }

  if (dronesFlying) {
    return 'Drones are flying.';
  }

  return 'Drones are on their chargers.';
};

export const getLockReason = (lock_reason: IFlightDomainStatusSTLockReasonEnum) => {
  switch (lock_reason) {
    case IFlightDomainStatusSTLockReasonEnum.DroneOperatingHoursEnd:
      return renderLockReason[LOCK_REASON.DRONE_OPERATING_HOURS_END];
    case IFlightDomainStatusSTLockReasonEnum.EmergencyLand:
      return renderLockReason[LOCK_REASON.EMERGENCY_LAND];
    case IFlightDomainStatusSTLockReasonEnum.FleetDecimated:
      return renderLockReason[LOCK_REASON.FLEET_DECIMATED];
    case IFlightDomainStatusSTLockReasonEnum.Manual:
      return renderLockReason[LOCK_REASON.MANUAL];
    case IFlightDomainStatusSTLockReasonEnum.ScheduledLock:
      return renderLockReason[LOCK_REASON.SCHEDULED_LOCK];
    case IFlightDomainStatusSTLockReasonEnum.SystemInitialization:
      return renderLockReason[LOCK_REASON.SYSTEM_INITIALIZATION];
    case IFlightDomainStatusSTLockReasonEnum.Null:
    default:
      return '-';
  }
};

/**
 * Processed flight domain status data for BaseCard component
 * @param flightDomain current flight domain
 * @param noFlyZones flight domain no_fly_zones data
 * @param navigate navigate hook for action button
 */
export const getFlightDomainStatusCardData = (flightDomain: IFlightDomainData) => {
  const timezone = LocalStore.getTimezone();

  const flightDomainSections = flightDomain.flight_domain_status
    ? [
        {
          sectionItems: [
            {
              id: 'fd-locked-unlocked-by',
              label: flightDomain.flight_domain_status.locked ? 'Locked by' : 'Unlocked by',
              value: flightDomain.flight_domain_status.triggered_by,
              testId: 'locked-unlocked-by',
            },
            {
              id: 'fd-time',
              label: 'Time',
              value: moment(flightDomain.flight_domain_status.last_locked_unlocked_time)
                .tz(timezone)
                .format(DATETIME_FORMAT_FOOTER),
              testId: 'time',
            },
            {
              id: 'fd-due-to',
              label: 'Due to',
              value: flightDomain.flight_domain_status.locked
                ? getLockReason(flightDomain.flight_domain_status.lock_reason)
                : '-',
              testId: 'due-to',
              visible: flightDomain.flight_domain_status.locked,
            },
          ],
        },
      ]
    : [];

  return {
    title: 'Lock status',
    counter: 'switch',
    testId: 'c-domain-status-overview-card',
    sections: flightDomainSections,
  };
};

/**
 * Processed flight domain data for BaseCard component
 * @param flightDomain current flight domain
 * @param navigate navigate hook for action button
 * @param theme theme
 */
export const getFleetCardData = ({
  systemId,
  flightDomain,
  nDronesLanded,
  isExecutingEmergencyLanding,
  isExecutingReturnHome,
  dronesFlying,
  navigate,
  theme,
}: {
  systemId: string;
  flightDomain: IFlightDomainData;
  nDronesLanded: number;
  isExecutingEmergencyLanding: boolean;
  isExecutingReturnHome: boolean;
  dronesFlying: boolean;
  navigate: NavigateFunction;
  theme: Theme;
}) => {
  const generateStyle = (value: number) => ({
    label: {
      color: theme.palette.textSecondary.main,
    },
    value: {
      fontSize: '1.3rem',
      fontWeight: value > 0 ? 'bold' : 'normal',
      color: value > 0 ? theme.palette.textSecondary.main : theme.palette.disabled,
    },
  });

  const isTriggeredBySystem = flightDomain.flight_domain_status?.triggered_by === 'System';

  const numberOfDronesFlying = isNil(flightDomain.fleet_status_summary?.num_drones_flying)
    ? -1
    : (flightDomain.fleet_status_summary?.num_drones_flying as number);

  const numberOfDronesOnCharger = isNil(flightDomain.fleet_status_summary?.num_drones_on_charger)
    ? -1
    : (flightDomain.fleet_status_summary?.num_drones_on_charger as number);

  const numberOfDronesLandedInError = isNil(
    flightDomain.fleet_status_summary?.num_drones_landed_in_error,
  )
    ? -1
    : (flightDomain.fleet_status_summary?.num_drones_landed_in_error as number);

  const counter = flightDomain.fleet_status_summary
    ? numberOfDronesFlying + numberOfDronesOnCharger + numberOfDronesLandedInError
    : -1;

  return {
    title: 'Fleet',
    subtitle: renderFleetSummaryInfo(
      nDronesLanded,
      isExecutingEmergencyLanding,
      isExecutingReturnHome,
      isTriggeredBySystem,
      dronesFlying,
    ),
    testId: 'c-fleet-summary-card',
    counter,
    actionButtons: [
      {
        variant: 'outlined',
        color: 'primary',
        fullWidth: true,
        testId: 'c-fleet-summary-card-action-btn',
        label: 'See Fleet Details',
        clickHandler: () => {
          navigate(`/${systemId}${GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).FLEET}`);
        },
      },
    ],
    sectionItems: [
      {
        id: 'fleet-drones-flying',
        icon: <DroneIcon color={theme.palette.grey[600]} />,
        label: 'FLYING',
        value: numberOfDronesFlying,
        style: () => generateStyle(numberOfDronesFlying),
        testId: 'drones-flying',
      },
      {
        id: 'fleet-drones-on-charger',
        icon: (
          <DroneOnChargerIcon
            colorActive={theme.palette.primary.light}
            colorInactive={theme.palette.grey[600]}
            isActive={false}
          />
        ),
        label: 'ON CHARGER',
        value: numberOfDronesOnCharger,
        style: () => generateStyle(numberOfDronesOnCharger),
        testId: 'drones-on-charger',
      },
      {
        id: 'fleet-drones-error',
        icon: <WarningAmberIcon sx={{ color: theme.palette.grey[600] }} />,
        label: 'ERRORS',
        value: numberOfDronesLandedInError,
        style: () => generateStyle(numberOfDronesLandedInError),
        testId: 'drones-error',
      },
    ],
  };
};

/**
 * Processed flight domain no_fly_zones data for BaseCard component
 * @param flightDomain current flight domain
 * @param noFlyZones flight domain no_fly_zones data
 * @param navigate navigate hook for action button
 */
export const getNoFlyZonesCardData = ({
  systemId,
  flightDomain,
  noFlyZones,
  navigate,
  isCoexistenceEnabled,
}: {
  systemId: string;
  flightDomain: IFlightDomainData;
  noFlyZones: INoFlyZonesResponseST;
  navigate: NavigateFunction;
  isCoexistenceEnabled: boolean;
}) => {
  const activeZones = isNil(noFlyZones.no_fly_zones)
    ? -1
    : Object.values(noFlyZones.no_fly_zones).filter((nfz) => !nfz.drone_allowed_in_zone).length;
  const inactiveZones = isNil(noFlyZones.no_fly_zones)
    ? -1
    : Object.values(noFlyZones.no_fly_zones).filter((nfz) => nfz.drone_allowed_in_zone).length;

  return {
    title: 'No-fly zones',
    subtitle: 'No-fly zones allow you to prevent drones from flying there.',
    testId: 'c-no-fly-zones-card',
    actionButtons: [
      {
        variant: 'outlined',
        color: 'primary',
        fullWidth: true,
        label: isCoexistenceEnabled ? 'See all drone zone details' : 'See no-fly zones details',
        clickHandler: () => {
          if (isCoexistenceEnabled) {
            navigate(
              `/${systemId}${GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).DRONE_ZONES}`,
            );
          } else {
            navigate(
              `/${systemId}${
                GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).NO_FLY_ZONES
              }`,
            );
          }
        },
        testId: 'c-no-fly-zones-card-action-btn',
      } as IBaseCardActionButton,
    ],
    sections: [
      {
        sectionItems: [
          {
            id: 'nfz-flying-not-allowed',
            label: isCoexistenceEnabled ? 'Flight prohibited in' : 'Flying not allowed in',
            value: !isEmpty(noFlyZones) ? activeZones : '-',
            testId: 'flying-not-allowed',
          },
          {
            id: 'nfz-flying-allowed',
            label: isCoexistenceEnabled ? 'Inactive' : 'Flying allowed in',
            value: !isEmpty(noFlyZones) ? inactiveZones : '-',
            testId: 'flying-allowed',
          },
        ],
      },
    ],
  };
};

/**
 * Processed flight domain no_fly_zones data for BaseCard component
 * @param flightDomainId current flight domain ID
 * @param noFlyZones flight domain no_fly_zones data
 * @param navigate navigate hook for action button
 */
export const getDroneShiftsCardData = (
  systemId: string,
  flightDomainId: string,
  flightDomainStatus: IFlightDomainStatusST,
  navigate: NavigateFunction,
) => {
  const timezone = LocalStore.getTimezone();

  const currentShiftStart = (
    flightDomainStatus.current_drone_operating_hours as IDroneOperatingHoursST
  )?.start;
  const currentShiftEnd = (
    flightDomainStatus.current_drone_operating_hours as IDroneOperatingHoursST
  )?.end;

  const nextShiftStart = (flightDomainStatus.next_drone_operating_hours as IDroneOperatingHoursST)
    ?.start;
  const nextShiftEnd = (flightDomainStatus.next_drone_operating_hours as IDroneOperatingHoursST)
    ?.end;

  const isCurrentShiftStarted =
    currentShiftStart && moment(currentShiftStart).tz(timezone).isBefore(moment.tz(timezone));

  const isNextShiftStarted =
    nextShiftStart && moment(nextShiftStart).tz(timezone).isBefore(moment.tz(timezone));

  const getTotalTime = (startTime: any, endTime: any) => {
    let diffInHours;
    const diffInMinutes = moment(endTime)
      .tz(timezone)
      .diff(moment(startTime).tz(timezone), 'minutes');

    if (diffInMinutes > 60) {
      diffInHours = Math.floor(diffInMinutes / 60);
      return `${diffInHours}h`;
    }
    return `${diffInMinutes}m`;
  };

  const renderSubtitle = () => {
    let subtitle: any = '';

    if (nextShiftStart && !isNextShiftStarted) {
      subtitle = formatDateTimeByTimeZoneAndCalendarSpec({
        text: 'Starts',
        datetime: nextShiftStart,
        timezone,
      });
    }

    if (isCurrentShiftStarted) {
      subtitle = formatDateTimeByTimeZoneAndCalendarSpec({
        text: 'Started',
        datetime: currentShiftStart,
        timezone,
      });
    }

    if (flightDomainStatus.next_scheduled_lock) {
      subtitle = (
        <Typography display="flex" alignItems="center" color="textSecondary">
          <LockIcon sx={{ mr: 0.6 }} fontSize="small" />
          {formatDateTimeByTimeZoneAndCalendarSpec({
            text: 'Schedule lock planned for',
            datetime: flightDomainStatus.next_scheduled_lock,
            timezone,
          })}
        </Typography>
      );
    }
    return subtitle;
  };

  const renderCounterObject = () => {
    if (isCurrentShiftStarted && currentShiftEnd) {
      return `ends ${moment(currentShiftEnd)
        .tz(timezone)
        .calendar(null, FLIGHT_DOMAIN_DRONE_SHIFT_FORMAT)}`;
    }
    return '';
  };

  return {
    title: 'Drones flight hours',
    subtitle: renderSubtitle(),
    testId: 'c-drone-flight-hours-card',
    counter: isCurrentShiftStarted ? 'active' : 'inactive',
    counterSubject: renderCounterObject(),
    actionButtons: [
      {
        variant: 'outlined',
        color: 'primary',
        fullWidth: true,
        label: 'See drone flight hours',
        testId: 'c-drone-flight-hours-card-action-btn',
        clickHandler: () => {
          navigate(`/${systemId}${GROUND_CONTROL_PAGES_URLS(flightDomainId).SCHEDULE}`);
        },
      },
    ],
    sections: [
      {
        sectionTitle: 'Current:',
        isVisible: isCurrentShiftStarted,
        sectionItems: [
          {
            id: 'current-shift-starts',
            label: 'Started',
            value: moment(currentShiftStart).tz(timezone).calendar(null, RELATIVE_DATETIME_FORMAT),
            tesId: 'started',
          },
          {
            id: 'current-shift-ends',
            label: 'Ends',
            value: moment(currentShiftEnd).tz(timezone).calendar(null, RELATIVE_DATETIME_FORMAT),
            tesId: 'ends',
          },
          {
            id: 'current-shift-total-time',
            label: 'Total time',
            value: getTotalTime(currentShiftStart, currentShiftEnd),
            tesId: 'total-time',
          },
        ],
      },
      {
        sectionTitle: 'Next:',
        isVisible: nextShiftStart,
        sectionItems: [
          {
            id: 'next-shift-total-starts',
            label: 'Starts',
            value: moment(nextShiftStart).tz(timezone).calendar(null, RELATIVE_DATETIME_FORMAT),
            testId: 'started',
          },
          {
            id: 'next-shift-total-ends',
            label: 'Ends',
            value: moment(nextShiftEnd).tz(timezone).calendar(null, RELATIVE_DATETIME_FORMAT),
            testId: 'ends',
          },
          {
            id: 'next-shift-total-time',
            label: 'Total time',
            value: getTotalTime(nextShiftStart, nextShiftEnd),
            testId: 'total-time',
          },
        ],
      },
    ],
  };
};
