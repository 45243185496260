import { ILocationDataST, IVeritySlotStatusST, IWMSSlotStatusST } from 'codegen/warehouse_status';
import moment from 'moment';

export const getIncludedExcluded = (locationData: ILocationDataST[]) => {
  const excluded = locationData.filter((l) => l.slot_settings?.exclusion_status === 'EXCLUDE');
  const included = locationData.filter((l) => l.slot_settings?.exclusion_status !== 'EXCLUDE');
  return { included, excluded };
};

const getValid = (locationData: ILocationDataST[]) =>
  locationData.filter((l) => (l.wms_status as IWMSSlotStatusST)?.state !== 'INVALID');

const getInvalid = (locationData: ILocationDataST[]) =>
  locationData.filter((l) => (l.wms_status as IWMSSlotStatusST)?.state === 'INVALID');

export const getIssueBreakdown = (locationData: ILocationDataST[]) => {
  const active = locationData.filter(
    (l) => l.issues[0]?.state === 'NEW' || l.issues[0]?.state === 'KNOWN',
  );

  const countByType = (t: string) => active.filter((l) => l.issues[0]?.type === t).length;

  const issues: { [key: string]: number } = {
    ISSUE1_W_BC_V_EMPTY: countByType('ISSUE1_W_BC_V_EMPTY'),
    ISSUE2_W_BC_NE_V_BC: countByType('ISSUE2_W_BC_NE_V_BC'),
    ISSUE3_W_EMPTY_V_BC: countByType('ISSUE3_W_EMPTY_V_BC'),
    ISSUE4_W_EMPTY_V_NOTEMPTY: countByType('ISSUE4_W_EMPTY_V_NOTEMPTY'),
    ISSUE5_W_BC_DUPLICATE: countByType('ISSUE5_W_BC_DUPLICATE'),
  };

  return issues;
};

export const getCoverage = (locationData: ILocationDataST[]) => {
  const { included, excluded } = getIncludedExcluded(locationData);

  const valid = getValid(included);
  const invalid = getInvalid(included);

  const noWmsNoVerity = valid.filter((l) => !l.wms_status && !l.verity_status).length;
  const wmsOnly = valid.filter((l) => l.wms_status && !l.verity_status).length;
  const verityOnly = valid.filter((l) => !l.wms_status && l.verity_status).length;
  const visited = valid.filter((l) => l.wms_status && l.verity_status);
  const upToDate = visited.filter((l) =>
    moment((l.wms_status as IWMSSlotStatusST).updated_at).isSameOrBefore(
      (l.verity_status as IVeritySlotStatusST).collected_at,
    ),
  ).length;
  const staleData = visited.filter((l) =>
    moment((l.wms_status as IWMSSlotStatusST).updated_at).isAfter(
      (l.verity_status as IVeritySlotStatusST).collected_at,
    ),
  ).length;

  return {
    noWmsNoVerity,
    wmsOnly,
    verityOnly,
    visited: visited.length,
    upToDate,
    staleData,
    excluded: excluded.length,
    included: included.length,
    invalid: invalid.length,
  };
};
