import { IInventoryRequestST } from 'codegen/inventory_request';
import { IReportST } from 'codegen/report';
import {
  getReqParamUserFacingName,
  getReqParamUserFacingValues,
} from 'common/functions/requestParameterFunctions';
import { reportServices } from '../../../api/ReportServices';
import { getReportExecutionData } from '../report-execution/getReportExecutionData';
import { getReportSummaryData } from '../report-summary/getReportSummaryData';
import { ReportSummary } from '../ReportStore.model';

/**
 * Get report summary
 */
export const getReportSummary = (
  systemId: string,
  id: string,
  signal: AbortSignal,
): Promise<ReportSummary> =>
  reportServices.getReportSummary(systemId, id, signal).then((r) => {
    const reportSummary: IReportST = r.data.item;
    const reportNeedsReview = !!reportSummary.num_locations_to_review;
    let requestParams: any = [];

    reportSummary.request.params.forEach((param) => {
      const labelString = getReqParamUserFacingName(
        param,
        reportSummary.request as IInventoryRequestST,
      );
      const valueString = getReqParamUserFacingValues(
        param,
        reportSummary.request as IInventoryRequestST,
      );

      // We ignore request params that have no values or aliases for their values
      // for example PALLET_LOCATION, on an Empty-Locations inventory request
      if (valueString !== '') {
        requestParams = [
          ...requestParams,
          {
            label: labelString,
            value: valueString,
          },
        ];
      }
    });

    const reportLocationData = getReportSummaryData(reportSummary);

    const reportExecutionData = getReportExecutionData(requestParams, reportSummary);

    return {
      fullResponse: reportSummary,
      reportLocationData,
      reportExecutionData,
      reportNeedsReview,
    };
  });
