import { GridValueGetter } from '@mui/x-data-grid';
import { ILocationDataST } from 'codegen/warehouse_status';
import { getUnknownBarcodes, getFoundBarcodes } from '../barcodeFunctions';
import { emptyCellString } from './collectionDelayValueGetter';

export const unknownBarcodesPercentageValueGetter: GridValueGetter<ILocationDataST> = (
  value,
  row,
): number | '-' => {
  const unknown = getUnknownBarcodes(row);
  const found = getFoundBarcodes(row);

  return unknown && found ? unknown.length / found.length : emptyCellString;
};
