import { GridFilterInputValue, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid';

export const notContainsOperator: GridFilterOperator<any, number> = {
  label: 'not contains',
  headerLabel: 'not contains',
  value: 'notContains',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    const filterRegex = new RegExp(filterItem.value, 'i');
    return (value) => !filterRegex.test(value?.toString() ?? '');
  },
  InputComponent: GridFilterInputValue,
  InputComponentProps: { type: 'string' },
};
