import { IFacilitySettingsST } from 'codegen/facility_settings';
import { SettingsItem } from '../models/settingsCard.model';

export const getBarcodesOptions = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: any) => void,
  errors: any,
): SettingsItem[] => [
  {
    type: 'number',
    testId: 'c-barcode-min-length-input',
    width: 'small',
    inputProps: { min: 1, max: facilitySettings.barcode_max_length, step: '1' },
    label: 'Barcode min length',
    description: 'The minimum length of a valid barcode.',
    value: facilitySettings.barcode_min_length,
    errors: errors.barcodeMin,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('barcode_min_length', Number(e.target.value)),
  },
  {
    type: 'number',
    testId: 'c-barcode-max-length-input',
    width: 'small',
    inputProps: { min: facilitySettings?.barcode_min_length ?? 1, step: '1' },
    label: 'Barcode max length',
    description: 'The maximum length of a valid barcode.',
    value: facilitySettings.barcode_max_length,
    errors: errors.barcodeMax,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('barcode_max_length', Number(e.target.value)),
  },
  {
    type: 'switch',
    label: 'Trim leading characters',
    testId: 'c-trim-leading-characters',
    description: `If enabled, leading characters are removed from the barcode value provided by Verity.
                    The system will remove as many as needed to have the barcode no longer than the closest smaller
                    or equal accepted length. For example, if you have 18 characters barcodes, set both min and max
                    to 18. If you have 11 and 18 characters barcodes, set min to 11, max to 18 and add
                    [12,13,14,15,16,17] to invalid lengths. This will result in the system accepting 11 and 18
                    characters for a barcode. With 11 and 18 characters accepted the system will trim as
                    follows: 19->18, 18->18, 17->11, ..., 12->11, 11->11`,
    value: facilitySettings.trim_leading_digits,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('trim_leading_digits', e.target.checked),
  },
  {
    type: 'switch',
    label: 'WMS Barcode sub-string match',
    testId: 'c-barcode_wms_substring_match',
    description: `If enabled, a WMS barcode is matched if it is contained in the barcode value provided
        by Verity (if disabled a full match is required)`,
    value: facilitySettings.barcode_wms_substring_match,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('barcode_wms_substring_match', e.target.checked),
  },
];
