import { createContext, useCallback, useContext, useReducer } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { TokenManager } from 'common/tokenManager';
import { AUTH_PAGES_URLS, NO_AUTH_PAGES } from 'common/pages';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { IModalsStoreContext } from './IClientModalsStore';
import { modalsReducer } from './clientModalsReducer';

import { initialClientModalsState } from './clientModalInitialState';
import { ClientModalsActionTypes } from './clientModalsActionTypes';

const tokenManager = TokenManager.getInstance();

const initialContext: IModalsStoreContext = {
  clientModalsState: initialClientModalsState,
  dispatchClientModals: () => {},
};

const Store = createContext(initialContext);

const logPrefix = getLogPrefixForType('COMPONENT', 'ClientModalsStoreProvider');

const AUTO_SIGN_OUT_AFTER_MINUTES = 50;

export const ClientModalsStoreProvider = ({ children }: any) => {
  const [clientModalsState, dispatchClientModals] = useReducer(
    modalsReducer,
    initialClientModalsState,
  );

  const navigate = useNavigate();

  const openSignInModal = useCallback(() => {
    localStorage.removeItem('last_check_in');
    if (clientModalsState.signinModalOpened || NO_AUTH_PAGES.includes(window.location.pathname)) {
      return;
    }
    console.debug(logPrefix, 'openSignInModal');

    dispatchClientModals({
      type: ClientModalsActionTypes.TOGGLE_SIGNIN_MODAL,
      payload: { open: true },
    });
    tokenManager.logoutUser();
  }, [clientModalsState.signinModalOpened, dispatchClientModals]);

  tokenManager.setOnRefreshTokenExpiration(openSignInModal);

  // NOTE: this is needed to take into account the case in which the browser gets closed
  // and the timeout function ceases to exist.
  // See UD - 4758(https://verity-ag.atlassian.net/browse/UD-4758)
  const localStorageAutoLogoutTime = localStorage.getItem('auto_log_out');
  const autoLogOutTime = moment(localStorageAutoLogoutTime).utc();
  const now = moment().utc();

  if (localStorageAutoLogoutTime && now.isAfter(autoLogOutTime)) {
    console.debug(
      logPrefix,
      'we are past the auto logout time => SIGNOUT',
      autoLogOutTime.toISOString(),
      now.toISOString(),
    );

    if (openSignInModal) {
      openSignInModal();
    }

    navigate(AUTH_PAGES_URLS.SIGNOUT);
  } else {
    console.debug(
      logPrefix,
      'we are before the auto logout time:',
      autoLogOutTime.toISOString(),
      now.toISOString(),
    );
  }

  /**
   * Function that will be called on idle timeout
   */
  const onIdle = useCallback(() => {
    console.debug(logPrefix, 'onIdle', 'idle detected => open sign in modal and logout user');
    openSignInModal && openSignInModal();
  }, [openSignInModal]);

  // Start the timer to trigger the opening of the sign in modal after N minutes of inactivity
  useIdleTimer({ onIdle, timeout: AUTO_SIGN_OUT_AFTER_MINUTES * 60 * 1000 });

  // Set the timeout value (in the storage) so, that in case the browser will be closed
  // and then re-opened, then the user will be logged out (if the N minutes of inactivity)
  // have elapsed. The +2 minutes are done to not collide with the sign in modal opener
  const timeout = moment()
    .utc()
    .add(AUTO_SIGN_OUT_AFTER_MINUTES + 2, 'minute')
    .toISOString();
  localStorage.setItem('auto_log_out', timeout);

  console.debug(logPrefix, 'auto sign out timer set', timeout);

  return (
    <Store.Provider value={{ clientModalsState, dispatchClientModals }}>{children}</Store.Provider>
  );
};

export const useClientModalsStore = () => useContext(Store);
