import { ClientLevelActionNames, ClientLevelAction } from './ClientLevelActions';
import { IClientLevelState, IClientLevelStateFacility } from './IClientLevelStore';

/**
 * initial state of the client level store
 */
export const initialClientLevelState: IClientLevelState = {
  facilityList: [] as IClientLevelStateFacility[],
  isMultiFacility: null,
  isFacilityListPopulated: false,
  isFacilityListLoading: false,
  failedToLoadFacilityList: false,
};

export const ClientLevelReducer = (state: IClientLevelState, action: ClientLevelAction) => {
  switch (action.type) {
    case ClientLevelActionNames.RESET:
      return { ...initialClientLevelState };

    case ClientLevelActionNames.SET_FACILITY_LIST:
      return {
        ...state,
        facilityList: action.payload,
        isMultiFacility: action.payload.length > 1,
      };

    case ClientLevelActionNames.SET_FAILED_TO_LOAD_FACILITY_LIST:
      return {
        ...state,
        failedToLoadFacilityList: action.payload,
      };

    case ClientLevelActionNames.SET_FACILITY_LIST_POPULATED:
      return {
        ...state,
        isFacilityListPopulated: action.payload,
      };

    case ClientLevelActionNames.SET_FACILITY_LIST_LOADING:
      return {
        ...state,
        isFacilityListLoading: action.payload,
      };

    case ClientLevelActionNames.UPDATE_FACILITY_ATTRIBUTE: {
      const { payload } = action;
      const facilityIndex = state.facilityList.findIndex((f) => f.id === payload.id);

      // check if the facility exists in the array
      if (facilityIndex >= 0) {
        // copy the facility we need to update
        const facilityToUpdate = state.facilityList[facilityIndex];
        // check if the attribute exists in that facility
        if (payload.key in facilityToUpdate) {
          // update the value of the attribute
          const facility = { ...facilityToUpdate, [payload.key]: payload.value };

          // return the facility list in the same order it was before
          return {
            ...state,
            facilityList: [
              ...state.facilityList.slice(0, facilityIndex),
              facility,
              ...state.facilityList.slice(facilityIndex + 1),
            ],
          };
        }
      }

      // in case the facility does not exist in the array, or in case the attribute
      // does not exist, return the state as it is
      return state;
    }

    default:
      return state;
  }
};
