import { useState, useEffect, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import { downloadInMemoryJSON } from 'common/functions/fileFunctions';
import { Box } from 'components/common/Box';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { ExpansibleDragAndDropFile } from 'components/common/ExpansibleDragAndDropFile/ExpansibleDragAndDropFile';
import { IReportSpecificationST } from 'codegen/report_specification';
import { ReportSpecItem } from './ReportSpecItem';

import { uploadStore } from '../../../store/UploadStore';
import { useFacilityModalsStore } from '../../../store/Modals';
import { FacilityModalsActionTypes } from '../../../store/Modals/types';

import { useRequestController } from '../../../hooks';

/**
 *
 * @returns Report specification upload card
 */
export function ReportSpecificationsUploadCard(props: { systemId: string }) {
  const { requestController } = useRequestController('ReportSpecificationsUploadCard');

  const { dispatchFacilityModals } = useFacilityModalsStore();

  const [reportSpecifications, setReportSpecifications] = useState([]);
  const [uploadInProgress, setUploadInProgress] = useState(false); // stops new entering

  const { systemId } = props;

  /**
   * card data
   */
  const facilityReportSpecs = {
    title: 'Report Specifications',
    subtitle: !isEmpty(reportSpecifications)
      ? 'Here are the current report specifications for this facility'
      : 'No report specifications available yet',
    actionButtons: undefined,
  };

  /**
   * Get current report specs for facility
   */
  const getReportSpecifications = useCallback(() => {
    requestController.doRequest({
      request: uploadStore.getReportSpecifications,
      requestParams: [systemId],
      messageErrorFallback: 'Failed to get the report specifications',
      callbackSuccess: (r) => setReportSpecifications(r),
      callbackFinally: () => setUploadInProgress(false),
    });
  }, [requestController, systemId]);

  /**
   * Handle upload report spec
   * @param files array of files to upload
   */
  const handleUpload = (files: File[]) => {
    setUploadInProgress(true);

    const fileReader = new FileReader();
    fileReader.readAsText(files[0], 'UTF-8');
    fileReader.onload = (e: Event) => {
      const { result } = e.target as FileReader;
      requestController.doRequest({
        request: uploadStore.uploadReportSpecification,
        requestParams: [systemId, JSON.parse(result as string)],
        messageSuccess: 'Report specification uploaded successfully',
        messageErrorFallback: 'Failed to upload report specification',
        callbackFinally: () => getReportSpecifications(),
      });
    };
  };

  /**
   * refresh data
   */
  const refreshData = {
    getReportSpec: () => getReportSpecifications(),
  };

  /**
   * Handle delete request
   * @param spec report specification data
   */
  const handleDelete = (spec: IReportSpecificationST) => {
    dispatchFacilityModals({
      type: FacilityModalsActionTypes.DELETE_REPORT_SPECIFICATION,
      refreshData,
      payload: {
        id: spec.id,
        reportSpecName: spec.report_spec_name,
      },
    });
  };

  /**
   * Download report specification
   * @param spec report specification data
   */
  const handleDownload = (spec: IReportSpecificationST) => {
    // Set file name
    const fileName = spec.report_spec_name.split(' ').join('_').toLowerCase();
    downloadInMemoryJSON(spec, fileName);
  };

  useEffect(() => {
    getReportSpecifications();
  }, [getReportSpecifications]);

  return (
    <BaseCard
      cardFor="report specifications"
      dataTestId="c-upload-report-spec-card"
      showHeader={true}
      showContent={true}
      showActionButtons={true}
      showHeaderDivider={true}
      title={facilityReportSpecs.title}
      subtitle={facilityReportSpecs.subtitle}
      actionButtons={facilityReportSpecs.actionButtons}
    >
      <Box px={2} pt={2}>
        <Box py={1}>
          {reportSpecifications?.map((spec: IReportSpecificationST) => (
            <ReportSpecItem
              key={spec.id}
              spec={spec}
              handleDelete={handleDelete}
              handleDownload={handleDownload}
            />
          ))}
        </Box>
      </Box>
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <ExpansibleDragAndDropFile
          title="Upload report specification"
          testId="c-report-spec-upload"
          dropzoneText="Drag and drop Report Specification file here..."
          uploadInProgress={uploadInProgress}
          onDrop={handleUpload}
        />
      </Box>
    </BaseCard>
  );
}
