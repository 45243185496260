import {
  getGridBooleanOperators,
  getGridDateOperators,
  getGridStringOperators,
  GridCellParams,
  GridColDef,
  GridTreeNode,
} from '@mui/x-data-grid-premium';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { ILocationDataST } from 'codegen/warehouse_status';
import { AllIssueTypes } from 'common/functions/issues/issueColorFunctions';
import {
  ContentExpectedCell,
  ContentFoundCell,
  ISSUE_STATE,
  IssueCell,
  IssueStateCell,
} from 'udb/inventory/shared/premium-grid/features/cell';
import { ContentShouldBeAtCell } from 'udb/inventory/shared/premium-grid/features/cell/ContentShouldBeAtCell';
import { ContentWasFoundAtCell } from 'udb/inventory/shared/premium-grid/features/cell/ContentWasFoundAtCell';
import { TooltipedCell } from 'udb/inventory/shared/premium-grid/features/cell/TooltipedCell';
import { WmsQuantityCell } from 'udb/inventory/shared/premium-grid/features/cell/WmsQuantityCell';
import { dateTimeValueFormatter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/dateTimeValueFormatter';
import { firstFoundOnGroupingValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/firstFoundOnGroupingValueGetter';
import { firstFoundOnValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/firstFoundOnValueGetter';
import { foundDateGroupingValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/foundDateGroupingValueGetter';
import { foundDateValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/foundDateValueGetter';
import { issueStatusValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/issueStatusValueGetter';
import { issueValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/issueValueGetter';
import { unknownBarcodesPercentageValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/unknownBarcodesPercentageValueGetter';
import { unknownBarcodesValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/unknownBarcodesValueGetter';
import { matchingBarcodesPercentageValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/matchingBarcodesPercentageValueGetter';
import { matchingBarcodesValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/matchingBarcodesValueGetter';
import { missingBarcodesPercentageValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/missingBarcodesPercentageValueGetter';
import { missingBarcodesValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/missingBarcodesValueGetter';
import { foundBarcodesValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/foundBarcodesValueGetter';
import { expectedBarcodesValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/expectedBarcodesValueGetter';
import { shouldBeAtValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/shouldBeAtValueGetter';
import { contentFoundValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/contentFoundValueGetter';
import { wmsDateGroupingValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/wmsDateGroupingValueGetter';
import { wmsDateValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/wmsDateValueGetter';
import { customerValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/customerValueGetter';
import { wmsQuantityValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/wmsQuantityValueGetter';
import { wmsArticleNumberValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/wmsArticleNumberValueGetter';
import { wasFoundAtValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/wasFoundAtValueGetter';
import { expectedContentValueGetter } from 'udb/inventory/shared/premium-grid/utils/cell-value-getters/expectedContentValueGetter';
import { isNotAnyOfOperator } from 'udb/inventory/shared/premium-grid/utils/custom-filters/isNotAnyOfOperator';
import { notContainsOperator } from 'udb/inventory/shared/premium-grid/utils/custom-filters/notContainsOperator';
import { amendedValueGetter } from '../../../../../shared/premium-grid/utils/cell-value-getters/amendedValueGetter';

// The render cell function should only return true when its a leaf node and not a group node
// or when its the autogenerated expandable group column
const isNotGroupCell = (field: string, rowNode: GridTreeNode): boolean =>
  !(rowNode.type === 'group' && !field.startsWith('__row_group'));

const customGridStringOperators = getGridStringOperators().filter(
  ({ value }) => !['isEmpty', 'isNotEmpty'].includes(value),
);

const customGridDateOperators = getGridDateOperators().filter(
  ({ value }) => !['isEmpty', 'isNotEmpty'].includes(value),
);

const specColumns = (facilitySettings: IFacilitySettingsST): GridColDef<ILocationDataST>[] => [
  {
    headerName: 'Location',
    field: 'slot_label',
    minWidth: 100,
    groupable: false,
    valueGetter: (_value, row) => row?.slot_label || '-',
    renderCell: (params) => isNotGroupCell(params.field, params.rowNode) && params.value,
  },
  {
    headerName: 'Content expected',
    field: 'wms_status.state',
    groupable: false,
    minWidth: 170,
    valueGetter: expectedContentValueGetter,
    renderCell: (params) => (
      <ContentExpectedCell facilitySettings={facilitySettings} locationData={params.row} />
    ),
  },
  ...(facilitySettings.disable_found_and_should_be_at
    ? []
    : ([
        {
          headerName: 'Was found at',
          field: 'wasFoundAt',
          groupable: false,
          valueGetter: wasFoundAtValueGetter,
          renderCell: (params) => <ContentWasFoundAtCell row={params.row} />,
        },
      ] as GridColDef<ILocationDataST>[])),
  {
    headerName: 'WMS article nr.',
    field: 'wms_status.article_nos.0',
    minWidth: 60,
    groupable: false,
    valueGetter: wmsArticleNumberValueGetter,
    groupingValueGetter: wmsArticleNumberValueGetter,
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
  },
  {
    headerName: 'WMS quantity',
    field: 'wms_status.qtys.0',
    groupable: false,
    minWidth: 60,
    valueGetter: wmsQuantityValueGetter,
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && <WmsQuantityCell row={params.row} />,
  },
  ...(facilitySettings.show_customer
    ? ([
        {
          headerName: 'Customer',
          field: 'wms_status.customers.0',
          groupable: true,
          width: 50,
          valueGetter: customerValueGetter,
          groupingValueGetter: customerValueGetter,
          renderCell: (params) =>
            isNotGroupCell(params.field, params.rowNode) && (
              <TooltipedCell value={params.formattedValue} />
            ),
        },
      ] as GridColDef<ILocationDataST>[])
    : []),
  {
    headerName: 'WMS date',
    field: 'wms_status.changed_at',
    minWidth: 130,
    type: 'dateTime',
    groupable: false,
    valueGetter: wmsDateValueGetter,
    valueFormatter: dateTimeValueFormatter,
    groupingValueGetter: wmsDateGroupingValueGetter,
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
    filterOperators: customGridDateOperators,
  },
  {
    headerName: 'Content found',
    field: 'verity_status.state',
    groupable: false,
    minWidth: 170,
    valueGetter: contentFoundValueGetter,
    renderCell: (params) => (
      <ContentFoundCell locationData={params.row} facilitySettings={facilitySettings} />
    ),
  },
  ...(facilitySettings.disable_found_and_should_be_at
    ? []
    : ([
        {
          headerName: 'Should be at',
          field: 'shouldBeAt',
          groupable: false,
          valueGetter: shouldBeAtValueGetter,
          renderCell: (params) => <ContentShouldBeAtCell row={params.row} />,
        },
      ] as GridColDef<ILocationDataST>[])),
  {
    headerName: 'Expected Barcodes',
    field: 'expected_barcodes',
    groupable: false,
    minWidth: 60,
    valueGetter: expectedBarcodesValueGetter,
  },
  {
    headerName: 'Found Barcodes',
    field: 'found_barcodes',
    groupable: false,
    minWidth: 60,
    valueGetter: foundBarcodesValueGetter,
  },
  {
    headerName: 'Missing Barcodes',
    field: 'missing_barcodes',
    groupable: false,
    minWidth: 60,
    valueGetter: missingBarcodesValueGetter,
  },
  {
    headerName: 'Missing Barcodes rate',
    field: 'missing_barcodes_rate',
    groupable: false,
    minWidth: 60,
    valueGetter: missingBarcodesPercentageValueGetter,
  },
  {
    headerName: 'Unexpected Barcodes',
    field: 'unexpected_barcodes',
    groupable: false,
    minWidth: 60,
    valueGetter: unknownBarcodesValueGetter,
  },
  {
    headerName: 'Unexpected Barcodes rate',
    field: 'unexpected_barcodes_rate',
    groupable: false,
    minWidth: 60,
    valueGetter: unknownBarcodesPercentageValueGetter,
  },
  {
    headerName: 'Matching Barcodes',
    field: 'matching_barcodes',
    groupable: false,
    minWidth: 60,
    valueGetter: matchingBarcodesValueGetter,
  },
  {
    headerName: 'Matching Barcodes rate',
    field: 'matching_barcodes_rate',
    groupable: false,
    minWidth: 60,
    valueGetter: matchingBarcodesPercentageValueGetter,
  },
  {
    headerName: 'Found date',
    field: 'contentFoundDate',
    minWidth: 140,
    type: 'dateTime',
    groupable: false,
    valueGetter: foundDateValueGetter,
    valueFormatter: dateTimeValueFormatter,
    groupingValueGetter: foundDateGroupingValueGetter,
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
    filterOperators: customGridDateOperators,
  },
  {
    headerName: 'First found on',
    field: 'issues.0.first_found_on',
    minWidth: 130,
    type: 'dateTime',
    groupable: false,
    valueGetter: firstFoundOnValueGetter,
    valueFormatter: dateTimeValueFormatter,
    groupingValueGetter: firstFoundOnGroupingValueGetter,
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <TooltipedCell value={params.formattedValue} />
      ),
    filterOperators: customGridDateOperators,
  },
  {
    headerName: 'Issue',
    field: 'issues.0.type',
    minWidth: 180,
    align: 'left',
    valueGetter: issueValueGetter,
    groupingValueGetter: issueValueGetter,
    filterOperators: [...customGridStringOperators, notContainsOperator, isNotAnyOfOperator],
    renderCell: (params) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <IssueCell issueType={(params.formattedValue as AllIssueTypes) || '-'} />
      ),
  },
  {
    headerName: 'Issue status',
    field: 'issues.0.state',
    minWidth: 90,
    align: 'left',
    valueGetter: issueStatusValueGetter,
    groupingValueGetter: issueStatusValueGetter,
    filterOperators: [...customGridStringOperators, notContainsOperator, isNotAnyOfOperator],
    renderCell: (params: GridCellParams<any>) =>
      isNotGroupCell(params.field, params.rowNode) && (
        <IssueStateCell state={params.formattedValue as ISSUE_STATE} />
      ),
  },
  {
    headerName: 'Amended',
    field: 'content_found_amended',
    groupable: true,
    minWidth: 60,
    valueGetter: amendedValueGetter,
    filterOperators: getGridBooleanOperators(),
    renderCell: (params) => (params.value ? 'yes' : ''),
  },
];

export const getColumns = (facilitySettings: IFacilitySettingsST): GridColDef[] =>
  specColumns(facilitySettings).map((col) => ({
    align: 'left',
    ...col,
    aggregable: false,
    headerClassName: 'datagrid-header',
    flex: 1,
    filterOperators: col.filterOperators ?? customGridStringOperators,
  }));
