import { Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { DISPLAY_VERITY_STATES } from 'common/slotStates';
import {
  ILocationDataST,
  ILocationDataSTStateEnum,
  IVeritySlotStatusST,
  IWMSSlotStatusST,
} from 'codegen/warehouse_status';
import { matchBarcodes } from 'common/functions/barcodes/matchBarcode.util';
import { IFacilitySettingsST, IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { barcodeStatusEnum } from 'components/ModalsAndPopups/LocationModal/barcodes/models/BarcodeRow.model';
import { getIssueAndBarcodeMatchingLogic } from 'common/functions/issueLogic/issueLogicFunctions';
import {
  AllIssueTypes,
  issueTypeEnumToColor,
  muiColorToTheme,
} from 'common/functions/issues/issueColorFunctions';
import { isDataStale, getContentCellColor } from './ContentCellFunctions';
import { multipleBarcode } from './multipleBarcode.style';
import { BarcodeLine } from './BarcodeLine';

const STATE_TO_MESSAGE_MAP: Partial<Record<ILocationDataSTStateEnum, string>> = {
  ABORTED: 'Out of time',
  UNREACHABLE: 'Unreachable Location',
  EXCLUDED: 'Excluded Location',
};

const getIssueColor = (
  locationData: ILocationDataST,
  theme: Theme,
  issueLogic: IRuleActionSTIssueLogicEnum | undefined,
) => {
  const hasIssues = !!locationData.issues?.length;
  if (hasIssues) {
    const issue = locationData.issues[0];
    const issueType = issueTypeEnumToColor[issue.type as AllIssueTypes];
    return muiColorToTheme(issueType, theme).main;
  }
  const isStale = isDataStale(locationData);
  const wmsStatus = locationData.wms_status as IWMSSlotStatusST;
  return getContentCellColor(!!wmsStatus, hasIssues, isStale, issueLogic);
};

export const ContentFoundCell = ({
  locationData,
  facilitySettings,
}: {
  locationData: ILocationDataST;
  facilitySettings: IFacilitySettingsST;
}) => {
  const theme = useTheme();

  const { classes } = multipleBarcode();

  const verityStatus = locationData.verity_status as IVeritySlotStatusST;
  const wmsStatus = locationData.wms_status as IWMSSlotStatusST;

  const stateCanBeMappedToMessage =
    locationData.state && Object.keys(STATE_TO_MESSAGE_MAP).includes(locationData.state);

  if (!verityStatus && locationData.state && stateCanBeMappedToMessage) {
    return <span className="c-data-grid-cell">{STATE_TO_MESSAGE_MAP[locationData.state]}</span>;
  }

  if (!verityStatus || typeof verityStatus === 'string') {
    return <span className="c-data-grid-cell">{verityStatus}</span>;
  }

  const { barcodes: verityBarcodes, user_overrides: userOverrides } = verityStatus;

  const showOverrides = userOverrides?.length;

  const barcodes = showOverrides
    ? userOverrides?.[userOverrides!.length - 1].barcodes
    : verityBarcodes;
  const state = showOverrides
    ? userOverrides?.[userOverrides!.length - 1].state
    : verityStatus.state;

  const { barcodeMatchLogic, issueLogic } = getIssueAndBarcodeMatchingLogic(
    locationData,
    facilitySettings,
  );

  const lastOverride = userOverrides![userOverrides!.length - 1];

  const renderTooltip = () => {
    if (showOverrides) {
      return (
        <span>
          value overridden: <br />
          by {lastOverride.user_name} <br />
          on {lastOverride.timestamp}. <br />
          Original value:{' '}
          <b>
            {verityBarcodes.length > 0
              ? verityBarcodes.join(' ,')
              : DISPLAY_VERITY_STATES[verityStatus.state]}
          </b>
        </span>
      );
    }

    if (!barcodes.length) {
      return DISPLAY_VERITY_STATES[verityStatus.state];
    }

    return barcodes.join(', ');
  };

  const renderContent = () => {
    if (!barcodes.length) {
      return (
        <Typography
          variant="body2"
          component="span"
          sx={{ color: getIssueColor(locationData, theme, issueLogic), cursor: 'default' }}
        >
          {DISPLAY_VERITY_STATES[state]}
          {showOverrides ? ' *' : ''}
        </Typography>
      );
    }

    const { unexpected } = matchBarcodes({
      expected: wmsStatus?.barcodes,
      found: verityStatus?.barcodes,
    });
    const list = barcodes.map((barcode) => {
      const status = unexpected.includes(barcode)
        ? barcodeStatusEnum.Unexpected
        : barcodeStatusEnum.Match;
      return (
        <BarcodeLine
          key={barcode}
          barcode={barcode}
          barcodeStatus={status}
          barcodeMatchLogic={barcodeMatchLogic}
          issueLogic={issueLogic}
          isOverride={!!showOverrides}
        />
      );
    });
    return <span className={classes.verticalCenter}>{list}</span>;
  };

  return (
    <Tooltip
      title={renderTooltip()}
      className="c-data-grid-cell c-data-grid-cell-content-found"
      data-testid="data-grid-cell"
    >
      {renderContent()}
    </Tooltip>
  );
};
