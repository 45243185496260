import React from 'react';
import { IFleetVersionResponseST, IFlightDomainStatusST } from 'codegen/flight_domain';
import { GroundControlLevelAction } from './groundControlLevelActions';
import { FleetOverviewST } from '../../pages/GroundControl/pages/FleetOverview/API';
import { IRequestController } from '../../hooks';

import { FlightDomainScheduleST } from '../../udb/ground-control/schedule/features/droneFlightHours/model/operatingHours.model';

export interface IGroundControlState {
  flightDomainLoaded: boolean;
  isExecutingEmergencyLanding: boolean;
  isExecutingReturnHome: boolean;
  dronesFlying: boolean;
  flightDomain: IFlightDomainData;
  flightDomainSchedule: FlightDomainScheduleST;
  fleetOverview: FleetOverviewST;
  fleetVersions: IFleetVersionResponseST;
  webSocketError: boolean; // <= TBD
  alertInfo: IAlertInfo;
  activeAlertVariant: string;
}

/**
 * Server type for a FleetStatusSummary.
 */
export type FleetStatusSummaryST = {
  num_drones_flying: number;
  num_drones_on_charger: number;
  num_drones_landed_in_error: number;
  updated_at: string;
};

/**
 * Server type for a FlightDomain.
 */
export interface IFlightDomainData {
  flight_domain_id: string;
  flight_domain_name: string;
  flight_domain_status?: IFlightDomainStatusST | null;
  fleet_status_summary?: FleetStatusSummaryST | null;
  isLoadingFleetData: boolean;
}

interface IAlertInfo {
  main: IAlertInfoData;
  fleetCard: IAlertInfoData;
  statusCard: IAlertInfoData;
  flightHoursCard: IAlertInfoData;
}

export interface IAlertInfoData {
  isOpened: boolean;
  variant?: string;
  message?: string;
}

/**
 * Fleet icon tooltips values
 */
export enum TooltipTypes {
  FLYING = 'FLYING',
  ON_CHARGERS = 'ON CHARGERS',
  ERRORS = 'ERRORS',
}

export interface IGroundControlLevelContext {
  stateGroundControl: IGroundControlState;
  dispatchGroundControlLevel: React.Dispatch<GroundControlLevelAction>;
  getFlightDomainData: (
    requestController: IRequestController,
    systemId: string,
    flightDomainId: string,
  ) => void;
  refreshFlightDomainData: (
    requestController: IRequestController,
    systemId: string,
    flightDomainId: string,
  ) => void;
  isDataReady: () => boolean;
  asyncGetFleetOverview: (
    requestController: IRequestController,
    flightDomainId: string,
  ) => Promise<FleetOverviewST>;
  asyncGetFleetVersion: (
    requestController: IRequestController,
    flightDomainId: string,
  ) => Promise<IFleetVersionResponseST>;
  populateFlightDomainSchedule: (flightDomainId: string) => Promise<any>;
  saveFlightDomainSchedule: (schedule: FlightDomainScheduleST) => Promise<any>;
  asyncGetFlightDomainInfo: (
    requestController: IRequestController,
    systemId: string,
    flightDomainId: string,
  ) => void;
}
