import { useEffect, useState } from 'react';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { useGroundControlStore } from 'store/GroundControl/groundControlLevelStore';
import { ScheduleLockEmptyState } from './features/scheduleLockEmptyState/ScheduleLockEmptyState';
import { ScheduleLockCreation } from './features/scheduleLockCreation/ScheduleLockCreation';
import { ScheduleLockDeletion } from './features/scheduleLockDeletion/ScheduleLockDeletion';

export const SCHEDULE_LOCK_STATES = {
  empty: 'empty',
  creation: 'creation',
  deletion: 'deletion',
};

const COMPONENTS = {
  [SCHEDULE_LOCK_STATES.empty]: ScheduleLockEmptyState,
  [SCHEDULE_LOCK_STATES.creation]: ScheduleLockCreation,
  [SCHEDULE_LOCK_STATES.deletion]: ScheduleLockDeletion,
};

/**
 * Schedule lock
 * @returns component
 */
export const ScheduleLock = () => {
  const { stateGroundControl } = useGroundControlStore();

  const scheduleLock =
    stateGroundControl.flightDomain?.flight_domain_status?.next_scheduled_lock || '';

  const [currentComponent, setCurrentComponent] = useState('');

  const Component = currentComponent && COMPONENTS[currentComponent];

  useEffect(() => {
    const defaultComponent = scheduleLock
      ? SCHEDULE_LOCK_STATES.deletion
      : SCHEDULE_LOCK_STATES.empty;

    setCurrentComponent(defaultComponent);
  }, [scheduleLock]);

  return (
    <BaseCard
      data-testid="c-schedule-lock-card"
      cardFor="??"
      title="Drone schedule lock"
      subtitle="Plan your next early flight ending"
    >
      {Component && <Component onClick={setCurrentComponent} scheduleLock={scheduleLock} />}
    </BaseCard>
  );
};
