import { AxiosResponse } from 'axios';
import chunk from 'lodash/chunk';
import { Dispatch } from 'react';

import { CLIENT_SIDE_PAGINATION_SETTINGS, NETWORK_REQUEST_SETTINGS } from 'common/settings';
import { IRequestController } from 'hooks';
import { reviewStore } from 'store/ReviewStore';
import { EnqueueSnackbar } from 'ts-types/EnqueueSnackbar';
import { ILocationReportData } from 'udb/inventory/features/warehouse-status/warehouseStatusFunctions';
import {
  ReportContainerActionNames,
  ReportContainerActions,
} from '../reducer/ReportContainerActions';

type ApproveSlotsStatus = {
  reviewLocations: ILocationReportData[];
  requestController: IRequestController;
  getReportData: (reportId: string, nLocationsPerRequest: number) => void;
  enqueueSnackbar: EnqueueSnackbar;
  reportId: string;
  systemId: string;
  dispatch: Dispatch<ReportContainerActions>;
};

/**
 * This function is used for approving slots status
 * @param param ApproveSlotsStatus
 */
export const approveSlotsStatus = ({
  reviewLocations,
  requestController,
  getReportData,
  enqueueSnackbar,
  reportId,
  systemId,
  dispatch,
}: ApproveSlotsStatus) => {
  const locationsListToReview = chunk(
    reviewLocations,
    CLIENT_SIDE_PAGINATION_SETTINGS.SUBMIT_REPORT_REVIEW_PAGE_SIZE,
  );
  let promiseSet: Promise<AxiosResponse>[] = [];

  for (let i = 0; i < locationsListToReview.length; i += 1) {
    promiseSet = [
      ...promiseSet,
      requestController.doRequest({
        request: reviewStore.approveSlotsStatus,
        requestParams: [systemId, locationsListToReview[i]],
        messageErrorFallback: 'The Slot Status could not be approved.',
      }),
    ];
  }

  Promise.all(promiseSet)
    .then((r) => {
      // Note: this try-catch is a mitigation of the general issue that promises' call-back
      // are being executed after a component has been unmounted.
      // TODO: integrate cancellable promises.
      try {
        // Check if all requests finished successfully
        const allRequestsSuccessful = r.reduce(
          (acc, response) => acc && response?.status === 200,
          true,
        );

        getReportData(reportId, NETWORK_REQUEST_SETTINGS.REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB);

        if (allRequestsSuccessful) {
          enqueueSnackbar('Report review submitted successfully.', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(
            'Something went wrong while submitting the report review. Please try again.',
            { variant: 'error' },
          );
        }
      } catch (error) {
        console.error(error);
      }
    })
    .finally(() =>
      dispatch({
        type: ReportContainerActionNames.SET_ANY,
        payload: { fieldName: 'spinner', value: 0 },
      }),
    );
};
