import { IFacilitySettingsST } from 'codegen/facility_settings';
import { FacilityModalsActionTypes } from 'store/Modals/types';
import { IFacilityModalsAction } from 'store/Modals/facilityModals/IFacilityModalsActions';
import { SettingsItem } from '../models/settingsCard.model';

export const getSystemResetOptions = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: any) => void,
  initialSettings: IFacilitySettingsST,
  facilityName: string,
  dispatchFacilityModals: (value: IFacilityModalsAction) => void,
  loadData: () => void,
): SettingsItem[] => [
  {
    type: 'switch',
    testId: 'c-enable-whs-reset-input',
    label: 'Enable Warehouse Status Reset',
    description:
      'If enabled, the functionality to reset the warehouse status is exposed to the user.',
    value: facilitySettings.enable_warehouse_status_reset,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('enable_warehouse_status_reset', e.target.checked),
  },
  {
    type: 'button',
    testId: 'c-reset-system-button',
    text: 'Reset',
    disableItemControls: !initialSettings.enable_warehouse_status_reset,
    label: 'Reset Warehouse Status Data',
    action: () =>
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.SYSTEM_RESET,
        payload: { facilityName },
        refreshData: { refreshData: () => loadData() },
      }),
  },
];
