import isEmpty from 'lodash/isEmpty';
import { IIssueST, ILocationDataST, IWMSSlotStatusSTStateEnum } from 'codegen/report';
import { ILocationReportData } from 'udb/inventory/features/warehouse-status/warehouseStatusFunctions';
import { sort } from '../otherFunctions';
import {
  getVerityDateFromSlotStatus,
  getUserNameFromSlotStatus,
  getVeritySlotStatusVersionFromSlotStatus,
  getUserValueFromSlotStatus,
} from '../slot/utils/veritySlotFunctions';
import { getVerityValueFromSlotStatus } from '../slot/utils/getVerityValueFromSlotStatus';
import {
  getWMSDateFromSlotStatus,
  getWMSArticleNosFromSlotStatus,
  getWMSQtyFromSlotStatus,
  getWMSSlotStatusVersionFromSlotStatus,
  getWMSValueFromSlotStatus,
} from '../slot/utils/wmsSlotFunctions';
import { getLatestOverwriteFromSlotStatus } from '../slot/utils/getLatestOverwriteFromSlotStatus';
import { getSlotActions } from '../slot/utils/getSlotActions';
import { getIssueTypeFromSingleIssueForReviewTable } from '../issues/issueFunctions';

export type RowDataForReview = {
  id: string;
  location: string;
  wmsValue: {
    contentExpectedValues: string[];
    contentExpectedState: IWMSSlotStatusSTStateEnum | undefined;
  };
  wmsDate: string;
  verityValue: string | string[];
  verityDate: string;
  version: number | '-';
  userValue: string[];
  userName: string;
  wmsArticleNo: string[];
  wmsQty: string[];
  wmsSlotStatusVersion: number | null;
  issue: string | null;
  actions: {
    data: any;
    actions: {
      label: string;
      disabled: boolean;
    }[];
  };
  allowRedirection: boolean;
};

/**
 * Get row for locations to review table
 * @param location location
 * @param locationData slot status
 * @param issuesForLocation issues related to the location
 * @returns
 */
export const getRowForLocationsToReviewTable = (
  location: string,
  locationData: ILocationDataST,
  issuesForLocation: IIssueST[],
) => {
  const rowData: ILocationReportData = {} as ILocationReportData;
  let locationIssue: IIssueST | null = null;

  if (!isEmpty(issuesForLocation)) {
    const sortedIssuesData = sort({
      array: issuesForLocation,
      sortingOrder: 'desc',
      sortBy: 'updated_at',
    }) as IIssueST[];
    [locationIssue] = sortedIssuesData;
  }

  rowData.id =
    locationIssue && isEmpty(locationIssue)
      ? `${locationIssue.issue_id}?version=${locationIssue.version}`
      : location;

  rowData.location = locationData.slot_label ?? '';
  rowData.wmsValue = getWMSValueFromSlotStatus(locationData.wms_status).contentExpectedValues;
  rowData.wmsDate = getWMSDateFromSlotStatus(locationData.wms_status);

  // During the review stage, on locations to review
  // mark the verity value with a "*", in case it already has been reviewed by a verity user
  rowData.verityValue = getLatestOverwriteFromSlotStatus(locationData.verity_status, 'verity')
    ? [`${getVerityValueFromSlotStatus(locationData.verity_status)} *`]
    : getVerityValueFromSlotStatus(locationData.verity_status);

  rowData.verityDate = getVerityDateFromSlotStatus(locationData.verity_status);
  rowData.version = getVeritySlotStatusVersionFromSlotStatus(locationData.verity_status);
  rowData.userValue = getUserValueFromSlotStatus(locationData.verity_status);
  rowData.userName = getUserNameFromSlotStatus(locationData.verity_status);
  rowData.wmsArticleNo = getWMSArticleNosFromSlotStatus(locationData.wms_status);

  rowData.wmsQty = getWMSQtyFromSlotStatus(locationData.wms_status);
  rowData.wmsSlotStatusVersion = getWMSSlotStatusVersionFromSlotStatus(locationData.wms_status);
  rowData.issue = locationIssue
    ? getIssueTypeFromSingleIssueForReviewTable(locationIssue.state, locationIssue.type)
    : '';
  rowData.actions = getSlotActions(location, locationData.wms_status, locationData.verity_status);

  // TR::2020-01-08:: FIX-ME: Allow redirection to open the location amendment modal
  rowData.allowRedirection = false;

  return rowData;
};
