import { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { getInvalidEmailErrorMessage } from 'udb/sign-in/features/SignInForm/utils/getInvalidEmailErrorMessage.utils';
import { Box } from 'components/common/Box';
import { TooltipedIcon } from 'components/common/TooltipedIcon';
import { isValidEmailFormat } from 'utils/isValidEmailFormat';

export const EmailList = (props: {
  confirmChanges: (args: string[]) => void;
  currentList: any[];
  title: string;
  tooltip: string;
}) => {
  const { confirmChanges, currentList, title, tooltip } = props;

  const [value, setValue] = useState('');
  const [emails, setEmails] = useState<string[]>([]);

  /**
   * Add WMS whitelist sender email
   */
  const addEmailToWhitelist = () => {
    setValue('');
    confirmChanges([...emails, value]);
  };

  /**
   * Remove sender email
   * @param email email address
   */
  const removeEmail = (email: string) => {
    confirmChanges(emails.filter((item) => item !== email));
  };

  useEffect(() => {
    if (currentList) {
      setEmails(currentList);
    }
  }, [currentList]);

  return (
    <>
      <Divider />
      <Box pt={1}>
        <Box display="flex" p={2}>
          <Typography variant="h6" color="secondary">
            {title}
          </Typography>
          <TooltipedIcon tooltip={tooltip} icon={<InfoOutlinedIcon fontSize="small" />} />
        </Box>
        <Box px={2} pt={1} pb={2}>
          {!isEmpty(emails) &&
            emails.map((email) => (
              <Box justifyContent="space-between" alignItems="center" display="flex" key={email}>
                <Typography>{email}</Typography>
                <IconButton onClick={() => removeEmail(email)} color="error" size="large">
                  <RemoveCircleIcon />
                </IconButton>
              </Box>
            ))}
        </Box>
        <Divider />
        <Box px={2} justifyContent="space-between">
          <Box pt={3} display="flex" justifyContent="space-between">
            <TextField
              label="Enter email address"
              size="small"
              variant="outlined"
              fullWidth
              type="email"
              value={value}
              error={value ? !isValidEmailFormat(value) : false}
              helperText={value && getInvalidEmailErrorMessage(value)}
              onChange={(e) => setValue(e.target.value)}
              onBlur={(e) => setValue(e.target.value)}
              // onKeyUp={(e) => setValue(e.target.value)}
            />
            <IconButton
              disabled={!isValidEmailFormat(value)}
              onClick={addEmailToWhitelist}
              color="primary"
              size="large"
            >
              <AddRoundedIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};
